// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.179.0
//   protoc               v3.21.12
// source: subtrace/tunnel/tunnel.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";

export const protobufPackage = "subtrace.tunnel";

export enum Role {
  INSERT = 0,
  SELECT = 1,
  UNRECOGNIZED = -1,
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case "INSERT":
      return Role.INSERT;
    case 1:
    case "SELECT":
      return Role.SELECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Role.UNRECOGNIZED;
  }
}

export function roleToJSON(object: Role): string {
  switch (object) {
    case Role.INSERT:
      return "INSERT";
    case Role.SELECT:
      return "SELECT";
    case Role.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CompressionMode {
  COMPRESSION_NONE = 0,
  COMPRESSION_GZIP = 1,
  UNRECOGNIZED = -1,
}

export function compressionModeFromJSON(object: any): CompressionMode {
  switch (object) {
    case 0:
    case "COMPRESSION_NONE":
      return CompressionMode.COMPRESSION_NONE;
    case 1:
    case "COMPRESSION_GZIP":
      return CompressionMode.COMPRESSION_GZIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompressionMode.UNRECOGNIZED;
  }
}

export function compressionModeToJSON(object: CompressionMode): string {
  switch (object) {
    case CompressionMode.COMPRESSION_NONE:
      return "COMPRESSION_NONE";
    case CompressionMode.COMPRESSION_GZIP:
      return "COMPRESSION_GZIP";
    case CompressionMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** POST /api/CreateTunnel */
export interface Create {
}

export interface Create_Request {
  namespaceId?: string | undefined;
  role: Role;
}

export interface Create_Response {
  error: string;
  tunnelId: string;
  endpoint: string;
}

/** POST /api/ListOpenTunnels */
export interface ListOpen {
}

export interface ListOpen_Request {
  createAfterTime: bigint;
}

export interface ListOpen_Item {
  tunnelId: string;
  endpoint: string;
  role: Role;
  namespaceId: string;
}

export interface ListOpen_Response {
  error: string;
  tunnels: ListOpen_Item[];
}

export interface Insert {
  tunnelQueryId: string;
  events: string[];
}

export interface Select {
  tunnelQueryId: string;
  sqlStatement: string;
}

export interface Result {
  tunnelQueryId: string;
  tunnelError: string;
  clickhouseQueryId: string;
  clickhouseError: string;
  compressionMode: CompressionMode;
  compressedData: Uint8Array;
}

function createBaseCreate(): Create {
  return {};
}

export const Create = {
  encode(_: Create, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Create {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Create {
    return {};
  },

  toJSON(_: Create): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Create>, I>>(base?: I): Create {
    return Create.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Create>, I>>(_: I): Create {
    const message = createBaseCreate();
    return message;
  },
};

function createBaseCreate_Request(): Create_Request {
  return { namespaceId: undefined, role: 0 };
}

export const Create_Request = {
  encode(message: Create_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.namespaceId !== undefined) {
      writer.uint32(10).string(message.namespaceId);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Create_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreate_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.namespaceId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Create_Request {
    return {
      namespaceId: isSet(object.namespaceId) ? globalThis.String(object.namespaceId) : undefined,
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
    };
  },

  toJSON(message: Create_Request): unknown {
    const obj: any = {};
    if (message.namespaceId !== undefined) {
      obj.namespaceId = message.namespaceId;
    }
    if (message.role !== 0) {
      obj.role = roleToJSON(message.role);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Create_Request>, I>>(base?: I): Create_Request {
    return Create_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Create_Request>, I>>(object: I): Create_Request {
    const message = createBaseCreate_Request();
    message.namespaceId = object.namespaceId ?? undefined;
    message.role = object.role ?? 0;
    return message;
  },
};

function createBaseCreate_Response(): Create_Response {
  return { error: "", tunnelId: "", endpoint: "" };
}

export const Create_Response = {
  encode(message: Create_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== "") {
      writer.uint32(8002).string(message.error);
    }
    if (message.tunnelId !== "") {
      writer.uint32(10).string(message.tunnelId);
    }
    if (message.endpoint !== "") {
      writer.uint32(18).string(message.endpoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Create_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreate_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          if (tag !== 8002) {
            break;
          }

          message.error = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endpoint = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Create_Response {
    return {
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      tunnelId: isSet(object.tunnelId) ? globalThis.String(object.tunnelId) : "",
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
    };
  },

  toJSON(message: Create_Response): unknown {
    const obj: any = {};
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.tunnelId !== "") {
      obj.tunnelId = message.tunnelId;
    }
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Create_Response>, I>>(base?: I): Create_Response {
    return Create_Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Create_Response>, I>>(object: I): Create_Response {
    const message = createBaseCreate_Response();
    message.error = object.error ?? "";
    message.tunnelId = object.tunnelId ?? "";
    message.endpoint = object.endpoint ?? "";
    return message;
  },
};

function createBaseListOpen(): ListOpen {
  return {};
}

export const ListOpen = {
  encode(_: ListOpen, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOpen {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOpen();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListOpen {
    return {};
  },

  toJSON(_: ListOpen): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOpen>, I>>(base?: I): ListOpen {
    return ListOpen.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOpen>, I>>(_: I): ListOpen {
    const message = createBaseListOpen();
    return message;
  },
};

function createBaseListOpen_Request(): ListOpen_Request {
  return { createAfterTime: 0n };
}

export const ListOpen_Request = {
  encode(message: ListOpen_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createAfterTime !== 0n) {
      if (BigInt.asIntN(64, message.createAfterTime) !== message.createAfterTime) {
        throw new globalThis.Error("value provided for field message.createAfterTime of type int64 too large");
      }
      writer.uint32(8).int64(message.createAfterTime.toString());
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOpen_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOpen_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.createAfterTime = longToBigint(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOpen_Request {
    return { createAfterTime: isSet(object.createAfterTime) ? BigInt(object.createAfterTime) : 0n };
  },

  toJSON(message: ListOpen_Request): unknown {
    const obj: any = {};
    if (message.createAfterTime !== 0n) {
      obj.createAfterTime = message.createAfterTime.toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOpen_Request>, I>>(base?: I): ListOpen_Request {
    return ListOpen_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOpen_Request>, I>>(object: I): ListOpen_Request {
    const message = createBaseListOpen_Request();
    message.createAfterTime = object.createAfterTime ?? 0n;
    return message;
  },
};

function createBaseListOpen_Item(): ListOpen_Item {
  return { tunnelId: "", endpoint: "", role: 0, namespaceId: "" };
}

export const ListOpen_Item = {
  encode(message: ListOpen_Item, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tunnelId !== "") {
      writer.uint32(10).string(message.tunnelId);
    }
    if (message.endpoint !== "") {
      writer.uint32(18).string(message.endpoint);
    }
    if (message.role !== 0) {
      writer.uint32(24).int32(message.role);
    }
    if (message.namespaceId !== "") {
      writer.uint32(34).string(message.namespaceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOpen_Item {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOpen_Item();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endpoint = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.namespaceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOpen_Item {
    return {
      tunnelId: isSet(object.tunnelId) ? globalThis.String(object.tunnelId) : "",
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
      namespaceId: isSet(object.namespaceId) ? globalThis.String(object.namespaceId) : "",
    };
  },

  toJSON(message: ListOpen_Item): unknown {
    const obj: any = {};
    if (message.tunnelId !== "") {
      obj.tunnelId = message.tunnelId;
    }
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.role !== 0) {
      obj.role = roleToJSON(message.role);
    }
    if (message.namespaceId !== "") {
      obj.namespaceId = message.namespaceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOpen_Item>, I>>(base?: I): ListOpen_Item {
    return ListOpen_Item.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOpen_Item>, I>>(object: I): ListOpen_Item {
    const message = createBaseListOpen_Item();
    message.tunnelId = object.tunnelId ?? "";
    message.endpoint = object.endpoint ?? "";
    message.role = object.role ?? 0;
    message.namespaceId = object.namespaceId ?? "";
    return message;
  },
};

function createBaseListOpen_Response(): ListOpen_Response {
  return { error: "", tunnels: [] };
}

export const ListOpen_Response = {
  encode(message: ListOpen_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== "") {
      writer.uint32(8002).string(message.error);
    }
    for (const v of message.tunnels) {
      ListOpen_Item.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOpen_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOpen_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          if (tag !== 8002) {
            break;
          }

          message.error = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnels.push(ListOpen_Item.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOpen_Response {
    return {
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      tunnels: globalThis.Array.isArray(object?.tunnels)
        ? object.tunnels.map((e: any) => ListOpen_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListOpen_Response): unknown {
    const obj: any = {};
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.tunnels?.length) {
      obj.tunnels = message.tunnels.map((e) => ListOpen_Item.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOpen_Response>, I>>(base?: I): ListOpen_Response {
    return ListOpen_Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOpen_Response>, I>>(object: I): ListOpen_Response {
    const message = createBaseListOpen_Response();
    message.error = object.error ?? "";
    message.tunnels = object.tunnels?.map((e) => ListOpen_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInsert(): Insert {
  return { tunnelQueryId: "", events: [] };
}

export const Insert = {
  encode(message: Insert, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tunnelQueryId !== "") {
      writer.uint32(10).string(message.tunnelQueryId);
    }
    for (const v of message.events) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Insert {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInsert();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnelQueryId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.events.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Insert {
    return {
      tunnelQueryId: isSet(object.tunnelQueryId) ? globalThis.String(object.tunnelQueryId) : "",
      events: globalThis.Array.isArray(object?.events) ? object.events.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Insert): unknown {
    const obj: any = {};
    if (message.tunnelQueryId !== "") {
      obj.tunnelQueryId = message.tunnelQueryId;
    }
    if (message.events?.length) {
      obj.events = message.events;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Insert>, I>>(base?: I): Insert {
    return Insert.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Insert>, I>>(object: I): Insert {
    const message = createBaseInsert();
    message.tunnelQueryId = object.tunnelQueryId ?? "";
    message.events = object.events?.map((e) => e) || [];
    return message;
  },
};

function createBaseSelect(): Select {
  return { tunnelQueryId: "", sqlStatement: "" };
}

export const Select = {
  encode(message: Select, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tunnelQueryId !== "") {
      writer.uint32(10).string(message.tunnelQueryId);
    }
    if (message.sqlStatement !== "") {
      writer.uint32(18).string(message.sqlStatement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Select {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnelQueryId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sqlStatement = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Select {
    return {
      tunnelQueryId: isSet(object.tunnelQueryId) ? globalThis.String(object.tunnelQueryId) : "",
      sqlStatement: isSet(object.sqlStatement) ? globalThis.String(object.sqlStatement) : "",
    };
  },

  toJSON(message: Select): unknown {
    const obj: any = {};
    if (message.tunnelQueryId !== "") {
      obj.tunnelQueryId = message.tunnelQueryId;
    }
    if (message.sqlStatement !== "") {
      obj.sqlStatement = message.sqlStatement;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Select>, I>>(base?: I): Select {
    return Select.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Select>, I>>(object: I): Select {
    const message = createBaseSelect();
    message.tunnelQueryId = object.tunnelQueryId ?? "";
    message.sqlStatement = object.sqlStatement ?? "";
    return message;
  },
};

function createBaseResult(): Result {
  return {
    tunnelQueryId: "",
    tunnelError: "",
    clickhouseQueryId: "",
    clickhouseError: "",
    compressionMode: 0,
    compressedData: new Uint8Array(0),
  };
}

export const Result = {
  encode(message: Result, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tunnelQueryId !== "") {
      writer.uint32(10).string(message.tunnelQueryId);
    }
    if (message.tunnelError !== "") {
      writer.uint32(18).string(message.tunnelError);
    }
    if (message.clickhouseQueryId !== "") {
      writer.uint32(26).string(message.clickhouseQueryId);
    }
    if (message.clickhouseError !== "") {
      writer.uint32(34).string(message.clickhouseError);
    }
    if (message.compressionMode !== 0) {
      writer.uint32(40).int32(message.compressionMode);
    }
    if (message.compressedData.length !== 0) {
      writer.uint32(50).bytes(message.compressedData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Result {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tunnelQueryId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tunnelError = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clickhouseQueryId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clickhouseError = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.compressionMode = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.compressedData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Result {
    return {
      tunnelQueryId: isSet(object.tunnelQueryId) ? globalThis.String(object.tunnelQueryId) : "",
      tunnelError: isSet(object.tunnelError) ? globalThis.String(object.tunnelError) : "",
      clickhouseQueryId: isSet(object.clickhouseQueryId) ? globalThis.String(object.clickhouseQueryId) : "",
      clickhouseError: isSet(object.clickhouseError) ? globalThis.String(object.clickhouseError) : "",
      compressionMode: isSet(object.compressionMode) ? compressionModeFromJSON(object.compressionMode) : 0,
      compressedData: isSet(object.compressedData) ? bytesFromBase64(object.compressedData) : new Uint8Array(0),
    };
  },

  toJSON(message: Result): unknown {
    const obj: any = {};
    if (message.tunnelQueryId !== "") {
      obj.tunnelQueryId = message.tunnelQueryId;
    }
    if (message.tunnelError !== "") {
      obj.tunnelError = message.tunnelError;
    }
    if (message.clickhouseQueryId !== "") {
      obj.clickhouseQueryId = message.clickhouseQueryId;
    }
    if (message.clickhouseError !== "") {
      obj.clickhouseError = message.clickhouseError;
    }
    if (message.compressionMode !== 0) {
      obj.compressionMode = compressionModeToJSON(message.compressionMode);
    }
    if (message.compressedData.length !== 0) {
      obj.compressedData = base64FromBytes(message.compressedData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Result>, I>>(base?: I): Result {
    return Result.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Result>, I>>(object: I): Result {
    const message = createBaseResult();
    message.tunnelQueryId = object.tunnelQueryId ?? "";
    message.tunnelError = object.tunnelError ?? "";
    message.clickhouseQueryId = object.clickhouseQueryId ?? "";
    message.clickhouseError = object.clickhouseError ?? "";
    message.compressionMode = object.compressionMode ?? 0;
    message.compressedData = object.compressedData ?? new Uint8Array(0);
    return message;
  },
};

export interface DataLoaderOptions {
  cache?: boolean;
}

export interface DataLoaders {
  rpcDataLoaderOptions?: DataLoaderOptions;
  getDataLoader<T>(identifier: string, constructorFn: () => T): T;
}

type ProtoMetaMessageOptions = {
  options?: { [key: string]: any };
  fields?: { [key: string]: { [key: string]: any } };
  oneof?: { [key: string]: { [key: string]: any } };
  nested?: { [key: string]: ProtoMetaMessageOptions };
};

export interface ProtoMetadata {
  fileDescriptor: FileDescriptorProto;
  references: { [key: string]: any };
  dependencies?: ProtoMetadata[];
  options?: {
    options?: { [key: string]: any };
    services?: {
      [key: string]: { options?: { [key: string]: any }; methods?: { [key: string]: { [key: string]: any } } };
    };
    messages?: { [key: string]: ProtoMetaMessageOptions };
    enums?: { [key: string]: { options?: { [key: string]: any }; values?: { [key: string]: { [key: string]: any } } } };
  };
}

export const protoMetadata: ProtoMetadata = {
  fileDescriptor: FileDescriptorProto.fromPartial({
    "name": "subtrace/tunnel/tunnel.proto",
    "package": "subtrace.tunnel",
    "dependency": [],
    "publicDependency": [],
    "weakDependency": [],
    "messageType": [{
      "name": "Create",
      "field": [],
      "extension": [],
      "nestedType": [{
        "name": "Request",
        "field": [{
          "name": "namespace_id",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "namespaceId",
          "options": undefined,
          "proto3Optional": true,
        }, {
          "name": "role",
          "number": 2,
          "label": 1,
          "type": 14,
          "typeName": ".subtrace.tunnel.Role",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "role",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "_namespace_id", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }, {
        "name": "Response",
        "field": [{
          "name": "error",
          "number": 1000,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "error",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "tunnel_id",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "tunnelId",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "endpoint",
          "number": 2,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "endpoint",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "ListOpen",
      "field": [],
      "extension": [],
      "nestedType": [{
        "name": "Request",
        "field": [{
          "name": "create_after_time",
          "number": 1,
          "label": 1,
          "type": 3,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "createAfterTime",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }, {
        "name": "Item",
        "field": [{
          "name": "tunnel_id",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "tunnelId",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "endpoint",
          "number": 2,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "endpoint",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "role",
          "number": 3,
          "label": 1,
          "type": 14,
          "typeName": ".subtrace.tunnel.Role",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "role",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "namespace_id",
          "number": 4,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "namespaceId",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }, {
        "name": "Response",
        "field": [{
          "name": "error",
          "number": 1000,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "error",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "tunnels",
          "number": 1,
          "label": 3,
          "type": 11,
          "typeName": ".subtrace.tunnel.ListOpen.Item",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "tunnels",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Insert",
      "field": [{
        "name": "tunnel_query_id",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "tunnelQueryId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "events",
        "number": 2,
        "label": 3,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "events",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Select",
      "field": [{
        "name": "tunnel_query_id",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "tunnelQueryId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "sql_statement",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "sqlStatement",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Result",
      "field": [{
        "name": "tunnel_query_id",
        "number": 1,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "tunnelQueryId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "tunnel_error",
        "number": 2,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "tunnelError",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "clickhouse_query_id",
        "number": 3,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "clickhouseQueryId",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "clickhouse_error",
        "number": 4,
        "label": 1,
        "type": 9,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "clickhouseError",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "compression_mode",
        "number": 5,
        "label": 1,
        "type": 14,
        "typeName": ".subtrace.tunnel.CompressionMode",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "compressionMode",
        "options": undefined,
        "proto3Optional": false,
      }, {
        "name": "compressed_data",
        "number": 6,
        "label": 1,
        "type": 12,
        "typeName": "",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "compressedData",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "enumType": [{
      "name": "Role",
      "value": [{ "name": "INSERT", "number": 0, "options": undefined }, {
        "name": "SELECT",
        "number": 1,
        "options": undefined,
      }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "CompressionMode",
      "value": [{ "name": "COMPRESSION_NONE", "number": 0, "options": undefined }, {
        "name": "COMPRESSION_GZIP",
        "number": 1,
        "options": undefined,
      }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "service": [],
    "extension": [],
    "options": {
      "javaPackage": "",
      "javaOuterClassname": "",
      "javaMultipleFiles": false,
      "javaGenerateEqualsAndHash": false,
      "javaStringCheckUtf8": false,
      "optimizeFor": 1,
      "goPackage": "subtrace.dev/tunnel",
      "ccGenericServices": false,
      "javaGenericServices": false,
      "pyGenericServices": false,
      "phpGenericServices": false,
      "deprecated": false,
      "ccEnableArenas": true,
      "objcClassPrefix": "",
      "csharpNamespace": "",
      "swiftPrefix": "",
      "phpClassPrefix": "",
      "phpNamespace": "",
      "phpMetadataNamespace": "",
      "rubyPackage": "",
      "uninterpretedOption": [],
    },
    "sourceCodeInfo": {
      "location": [{
        "path": [4, 0],
        "span": [12, 0, 23, 1],
        "leadingComments": " POST /api/CreateTunnel\n",
        "trailingComments": "",
        "leadingDetachedComments": [],
      }, {
        "path": [4, 1],
        "span": [26, 0, 42, 1],
        "leadingComments": " POST /api/ListOpenTunnels\n",
        "trailingComments": "",
        "leadingDetachedComments": [],
      }],
    },
    "syntax": "proto3",
  }),
  references: {
    ".subtrace.tunnel.Role": Role,
    ".subtrace.tunnel.CompressionMode": CompressionMode,
    ".subtrace.tunnel.Create": Create,
    ".subtrace.tunnel.Create.Request": Create_Request,
    ".subtrace.tunnel.Create.Response": Create_Response,
    ".subtrace.tunnel.ListOpen": ListOpen,
    ".subtrace.tunnel.ListOpen.Request": ListOpen_Request,
    ".subtrace.tunnel.ListOpen.Item": ListOpen_Item,
    ".subtrace.tunnel.ListOpen.Response": ListOpen_Response,
    ".subtrace.tunnel.Insert": Insert,
    ".subtrace.tunnel.Select": Select,
    ".subtrace.tunnel.Result": Result,
  },
  dependencies: [],
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToBigint(long: Long) {
  return BigInt(long.toString());
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
