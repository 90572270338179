export type CancellableResult<T> = CancelledResult | FailureResult | SuccessResult<T>;

export type CancelledResult = { kind: CancellableResultKind.Cancelled };
export type FailureResult = { kind: CancellableResultKind.Failure; error: Error };
export type SuccessResult<T> = { kind: CancellableResultKind.Success; value: T };

export enum CancellableResultKind {
  Cancelled = "Cancelled",
  Failure = "Failure",
  Success = "Success",
}
