import React from "react";
import { Link } from "react-router-dom";

import * as StyleUtils from "Utils/StyleUtils";
import * as UrlUtils from "UrlUtils";

export function InternalLink(props: InternalLinkProps): React.ReactElement {
  return (
    <Link
      className={StyleUtils.mergeClassNames(
        props.showAsButton ? "text-sm text-zinc-200 font-medium rounded bg-sky-700/95 hover:bg-sky-700 group" : "rounded font-medium text-sm text-zinc-300/95 hover:text-zinc-300",
        props.className,
      )}
      to={{ pathname: props.target, search: UrlUtils.getPersistentURLSearchParams(window.location.search) }}
    >
      {props.label}
      {props.showArrow ? <span className="inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]">&rarr;</span> : null}
    </Link>
  );
}

export interface InternalLinkProps {
  className?: string;
  label: string;
  showArrow?: true;
  showAsButton?: boolean;
  target: string;
}
