import { CircleXIcon } from "lucide-react";
import * as React from "react";

import { Clause, ClauseKind } from "Clause";
import { NumericalFilterKind } from "NumericalFilter";
import { QueryResult } from "QueryResult";
import { Result } from "Utils/Result";
import { TextFilterKind } from "TextFilter";
import { Token, TokenKind } from "Tokenizer";
import * as BigIntUtils from "BigIntUtils";
import * as MapUtils from "Utils/MapUtils";
import * as ResultUtils from "Utils/ResultUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as Tokenizer from "Tokenizer";
import * as Verify from "Utils/Verify";

export const ClauseEditor = React.forwardRef((props: ClauseEditorProps, ref: React.ForwardedRef<ClauseEditorImperativeProps>) => {
  const [input, setInput] = React.useState<string>("");
  const [clauseId, setClauseId] = React.useState<string | undefined>(undefined);
  const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  // Make this an imperative action even though it can technically be expressed as a prop,
  // since it makes sense for the clause editor to own its input value even if it can
  // occasionally be modified from the outside.
  React.useImperativeHandle<ClauseEditorImperativeProps, ClauseEditorImperativeProps>(
    ref,
    () => ({
      setClauseBeingEdited: (clause): void => {
        setInput(clause.clauseText);
        unsetSuggestions();
        setClauseId(clause.clauseId);
        inputRef.current?.focus();
      },
    }),
    [],
  );

  const knownColumnTypesByName: Map<string, KnownColumnType> = new Map(
    props.queryResult?.meta
      .filter((meta) => meta.name !== SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time))
      .map((meta) => [meta.name, getKnownColumnType(meta.type)]) ?? [],
  );

  return (
    <div className="w-full h-8 relative font-mono text-xs font-medium">
      <div className="absolute w-full h-full px-3 rounded bg-zinc-900 outline outline-[1px] outline-zinc-800 text-zinc-600 pointer-events-none">
        <span className="flex flex-row items-center h-full">
          {renderInputText()}
          <span className="ml-4">
            {errorMessage !== undefined ? renderError(errorMessage) : activeSuggestionIndex !== undefined ? renderSuggestion(suggestions[activeSuggestionIndex]) : null}
          </span>
        </span>
      </div>
      <input
        className="text-transparent caret-white absolute w-full h-full px-3 bg-transparent focus-visible:outline-0 placeholder:text-zinc-600"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        placeholder={suggestions.length === 0 && errorMessage === undefined ? "example: http_req_method = 'GET'" : undefined}
        ref={inputRef}
        spellCheck={false}
        value={input}
      />
    </div>
  );

  function renderError(errorMessage: string): React.ReactElement {
    return (
      <span className="flex flex-row space-x-2 items-center text-red-600">
        <CircleXIcon size={16} />
        <span>{errorMessage}</span>
      </span>
    );
  }

  function renderInputText(): React.ReactNode {
    const inputTokens: Token[] = Tokenizer.tokenize(input);
    return inputTokens.map((token) => {
      let tokenClassName: string | undefined;
      switch (token.kind) {
        case TokenKind.Equal:
        case TokenKind.GreaterThan:
        case TokenKind.GreaterThanOrEqualTo:
        case TokenKind.LesserThan:
        case TokenKind.LesserThanOrEqualTo:
        case TokenKind.NotEqual:
          tokenClassName = "text-white";
          break;

        case TokenKind.Like:
        case TokenKind.Not:
        case TokenKind.NotLike:
          tokenClassName = "text-purple-500";
          break;

        case TokenKind.Identifier:
          tokenClassName = "text-sky-300";
          break;

        case TokenKind.Number:
          tokenClassName = "text-lime-100";
          break;

        case TokenKind.SingleQuotedString:
        case TokenKind.IncompleteSingleQuotedString:
          tokenClassName = "text-orange-300";
          break;

        case TokenKind.Whitespace:
          tokenClassName = undefined;
          break;

        case TokenKind.META_ERROR_CATCHALL:
          tokenClassName = "text-white";
          break;

        default:
          Verify.isNever(token.kind);
      }

      return (
        <span className={`whitespace-pre ${tokenClassName}`} key={`${token.kind}-${token.value}-${token.range.start}-${token.range.end}`}>
          {token.value}
        </span>
      );
    });
  }

  function renderSuggestion(suggestion: Suggestion): React.ReactNode {
    const inputTokens: Token[] = Tokenizer.tokenize(input);
    const textToBeReplaced: string = getTextToBeReplaced(inputTokens);

    if (textToBeReplaced === "") {
      return (
        <React.Fragment>
          <span>{suggestion.displayString}</span>
          {suggestion.suffixText ? <span className="text-zinc-700">{suggestion.suffixText}</span> : null}
        </React.Fragment>
      );
    }

    const parts: React.ReactElement[] = [];
    let currentIndex: number = 0;

    while (currentIndex < suggestion.displayString.length) {
      const matchIndex: number = suggestion.displayString.toLowerCase().indexOf(textToBeReplaced.toLowerCase(), currentIndex);
      if (matchIndex === -1) {
        break;
      }

      parts.push(<span>{suggestion.displayString.slice(currentIndex, matchIndex)}</span>);
      parts.push(
        <span className="text-zinc-500 underline underline-offset-4 decoration-zinc-500">{suggestion.displayString.slice(matchIndex, matchIndex + textToBeReplaced.length)}</span>,
      );
      currentIndex = matchIndex + textToBeReplaced.length;
    }

    parts.push(<span>{suggestion.displayString.slice(currentIndex)}</span>);

    return (
      <React.Fragment>
        {...parts}
        {suggestion.suffixText ? <span className="text-zinc-700">{suggestion.suffixText}</span> : null}
      </React.Fragment>
    );
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const input: string = event.target.value;
    setInput(input);
    setErrorMessage(undefined);

    // We only support "terminal" auto complete suggestions, nothing in the middle for now
    if (event.target.selectionStart !== event.target.selectionEnd || event.target.selectionStart !== input.length) {
      return;
    }

    // By default, the user needs to type a single character for us to give them meaningful suggestions.
    const inputTokens: Token[] = Tokenizer.tokenize(input);
    const textToBeReplaced: string = getTextToBeReplaced(inputTokens);
    if (textToBeReplaced.length < 1) {
      unsetSuggestions();
      return;
    }

    const suggestions: Suggestion[] = getSuggestions(inputTokens);
    setSuggestions(suggestions);
    setActiveSuggestionIndex(suggestions.length > 0 ? 0 : undefined);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Tab") {
      onTabKeyDown(event);
    } else if (event.key === "ArrowDown") {
      onDownArrowKeyDown(event);
    } else if (event.key === "ArrowUp") {
      onUpArrowKeyDown(event);
    } else if (event.key === "Enter") {
      onEnterKeyDown(event);
    } else if (event.key === "Escape") {
      onEscapeKeyDown();
    } else if (event.key === " " && event.ctrlKey) {
      onCtrlSpaceKeyDown(event);
    }
  }

  function onCtrlSpaceKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    // We only support "terminal" auto complete suggestions, nothing in the middle for now
    if (event.currentTarget.selectionStart !== event.currentTarget.selectionEnd || event.currentTarget.selectionStart !== input.length) {
      return;
    }

    const inputTokens: Token[] = Tokenizer.tokenize(input);
    const suggestions: Suggestion[] = getSuggestions(inputTokens);
    setSuggestions(suggestions);
    setActiveSuggestionIndex(suggestions.length > 0 ? 0 : undefined);
  }

  function onEscapeKeyDown(): void {
    setClauseId(undefined);
    unsetSuggestions();
    setInput("");
  }

  function onEnterKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    unsetSuggestions();

    const inputTokens: Token[] = Tokenizer.tokenize(input);
    const wordTokens: Token[] = inputTokens.filter((token) => token.kind !== TokenKind.Whitespace);
    if (wordTokens.length === 0) {
      setErrorMessage(`Invalid clause`);
      return;
    }

    const columnName: string = wordTokens[0].value;
    if (!knownColumnTypesByName.has(columnName)) {
      setErrorMessage(`Invalid clause: Unknown column name '${columnName}'`);
      return;
    }

    const columnType: KnownColumnType = MapUtils.getOrThrow(knownColumnTypesByName, columnName);
    if (wordTokens.length === 1) {
      let operatorsString: string;
      if (columnType === KnownColumnType.Number) {
        operatorsString = "'=' or '<'";
      } else if (columnType === KnownColumnType.String) {
        operatorsString = "'=' or 'contains'";
      } else {
        Verify.isNever(columnType);
      }

      setErrorMessage(`Invalid clause: Operator (like ${operatorsString}) expected after '${columnName}'`);
      return;
    }

    const operator: string = wordTokens[1].value;
    if (wordTokens.length === 2) {
      setErrorMessage(`Invalid clause: Value expected after '${operator}'`);
      return;
    }

    const valueToken: Token = wordTokens[2];
    const value: string = valueToken.value;

    if (columnType === KnownColumnType.String) {
      const filterKind: TextFilterKind | undefined = getTextFilterKind(operator);
      if (!filterKind) {
        setErrorMessage(`Invalid clause: Unknown operator '${operator}'`);
        return;
      }

      if (valueToken.kind !== TokenKind.SingleQuotedString) {
        setErrorMessage(`Invalid clause: Expected single quoted string, got "${value}" instead`);
        return;
      }

      const clause: Clause = {
        columnName,
        kind: ClauseKind.String,
        filter: {
          kind: filterKind,
          value,
        },
        clauseId: clauseId ?? crypto.randomUUID(),
        clauseText: input,
      };

      props.onClauseCommitted(clause);
      setInput("");
      setClauseId(undefined);
      return;
    }

    if (columnType === KnownColumnType.Number) {
      const filterKind: NumericalFilterKind | undefined = getNumericalFilterKind(operator);
      if (!filterKind) {
        setErrorMessage(`Invalid clause: Unknown operator '${operator}'`);
        return;
      }

      const result: Result<bigint, string> = BigIntUtils.parse(value);
      if (ResultUtils.isFailure(result)) {
        setErrorMessage(`Invalid clause: Value '${value}' is not a valid number`);
        return;
      }

      const clause: Clause = {
        columnName,
        kind: ClauseKind.Number,
        filter: {
          kind: filterKind,
          value: result.value,
        },
        clauseId: clauseId ?? crypto.randomUUID(),
        clauseText: input,
      };

      props.onClauseCommitted(clause);
      setInput("");
      setClauseId(undefined);
      return;
    }

    Verify.isNever(columnType);
  }

  function onTabKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (activeSuggestionIndex !== undefined) {
      event.preventDefault();
      const suggestion: Suggestion = suggestions[activeSuggestionIndex];
      setInput(input.slice(0, suggestion.startingPosition) + suggestion.replacementString);
      unsetSuggestions();
    }
  }

  function onDownArrowKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (activeSuggestionIndex !== undefined) {
      event.preventDefault();
      const newActiveSuggestionIndex: number = (activeSuggestionIndex + 1) % suggestions.length;
      setActiveSuggestionIndex(newActiveSuggestionIndex);
    }
  }

  function onUpArrowKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (activeSuggestionIndex !== undefined) {
      event.preventDefault();
      const newActiveSuggestionIndex: number = (activeSuggestionIndex + suggestions.length - 1) % suggestions.length;
      setActiveSuggestionIndex(newActiveSuggestionIndex);
    }
  }

  function getSuggestions(tokens: Token[]): Suggestion[] {
    const textToBeReplaced: string = getTextToBeReplaced(tokens);
    const wordTokens: Token[] = tokens.filter((token) => token.kind !== TokenKind.Whitespace);

    if (wordTokens.length >= 3 || (wordTokens.length === 2 && tokens[tokens.length - 1].kind === TokenKind.Whitespace)) {
      // Suggestions for values (RHS)
      const columnName: string = wordTokens[0].value;
      const columnType: KnownColumnType | undefined = knownColumnTypesByName.get(columnName);
      if (columnType !== KnownColumnType.String) {
        return [];
      }

      const columnValues: string[] = [...new Set(props.queryResult?.data.map((row) => row[columnName]).filter((value) => value != null))];
      const operatorName: string = wordTokens[1].value;
      const filterKind: TextFilterKind | undefined = getTextFilterKind(operatorName);
      if (!filterKind || filterKind === TextFilterKind.Like || filterKind === TextFilterKind.NotLike) {
        return [];
      }

      const startingPosition: number = wordTokens.length >= 3 ? wordTokens[2].range.start : tokens[tokens.length - 1].range.end;
      return columnValues
        .map((value) => `'${value}'`)
        .filter((value) => value.toLowerCase().includes(textToBeReplaced.toLowerCase()))
        .sort((suggestion1, suggestion2) => getMatchScore(textToBeReplaced, suggestion2) - getMatchScore(textToBeReplaced, suggestion1))
        .map((text, _, suggestions) => ({
          replacementString: text,
          displayString: text,
          startingPosition,
          suffixText: suggestions.length > 1 ? ` (and ${suggestions.length - 1} more)` : undefined,
        }));
    } else if (wordTokens.length === 2 || (wordTokens.length === 1 && tokens[tokens.length - 1].kind === TokenKind.Whitespace)) {
      // We don't provide any suggestions for operators
      return [];
    } else {
      // Suggestions for column names (LHS)
      const startingPosition: number = wordTokens.length === 1 ? wordTokens[0].range.start : tokens.length > 0 ? tokens[tokens.length - 1].range.end : 0;
      return [...knownColumnTypesByName.keys()]
        .filter((field) => field.toLowerCase().includes(textToBeReplaced.toLowerCase()) && field.toLowerCase() !== textToBeReplaced.toLowerCase())
        .sort((suggestion1, suggestion2) => getMatchScore(textToBeReplaced, suggestion2) - getMatchScore(textToBeReplaced, suggestion1))
        .map((text) => ({
          replacementString: text + " ",
          displayString: text,
          startingPosition,
        }));
    }
  }

  function unsetSuggestions(): void {
    setSuggestions([]);
    setActiveSuggestionIndex(undefined);
  }
});

function getKnownColumnType(columnType: string): KnownColumnType {
  switch (columnType) {
    case "UInt64":
    case "Nullable(UInt64)":
      return KnownColumnType.Number;

    case "String":
    case "Nullable(String)":
    case "LowCardinality(String)":
    case "UUID":
      return KnownColumnType.String;

    default:
      return KnownColumnType.String;
  }
}

function getTextToBeReplaced(tokens: Token[]): string {
  return tokens.length > 0 && tokens[tokens.length - 1].kind !== TokenKind.Whitespace ? tokens[tokens.length - 1].value : "";
}

function getMatchScore(input: string, suggestion: string): number {
  const lowerCaseInput: string = input.toLowerCase();
  const lowerCaseSuggestion: string = suggestion.toLowerCase();
  if (lowerCaseInput === lowerCaseSuggestion) {
    return -1;
  }
  if (lowerCaseSuggestion.startsWith(lowerCaseInput)) {
    return 2;
  }
  if (lowerCaseSuggestion.includes(lowerCaseInput)) {
    return 1;
  }
  return 0;
}

function getNumericalFilterKind(operator: string): NumericalFilterKind | undefined {
  switch (operator) {
    case "=":
      return NumericalFilterKind.Equals;

    case "!=":
    case "<>":
      return NumericalFilterKind.NotEquals;

    case ">":
      return NumericalFilterKind.GreaterThan;

    case ">=":
      return NumericalFilterKind.GreaterThanOrEquals;

    case "<":
      return NumericalFilterKind.LesserThan;

    case "<=":
      return NumericalFilterKind.LesserThanOrEquals;

    default:
      return undefined;
  }
}

function getTextFilterKind(operator: string): TextFilterKind | undefined {
  switch (operator) {
    case "=":
      return TextFilterKind.Equals;

    case "!=":
    case "<>":
      return TextFilterKind.DoesNotEqual;

    case "LIKE":
      return TextFilterKind.Like;

    case "NOT LIKE":
      return TextFilterKind.NotLike;

    default:
      return undefined;
  }
}

interface Suggestion {
  displayString: string;
  replacementString: string;
  startingPosition: number;
  suffixText?: string;
}

const enum KnownColumnType {
  String = "String",
  Number = "Number",
}

export interface ClauseEditorProps {
  queryResult: QueryResult | undefined;

  onClauseCommitted: (clause: Clause) => void;
}

export interface ClauseEditorImperativeProps {
  setClauseBeingEdited: (clause: Clause) => void;
}
