import React from "react";

import * as StyleUtils from "Utils/StyleUtils";

export function Overlay(props: React.PropsWithChildren<OverlayProps>): React.ReactElement | null {
  return (
    <div className={StyleUtils.mergeClassNames("bg-black/50 z-10", props.className)} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

export interface OverlayProps {
  className?: string;
  onClick?: () => void;
}
