import {
  CancellableResult,
  CancellableResultKind,
  CancelledResult,
  FailureResult,
  SuccessResult,
} from "Utils/CancellableResult";

// Constructors
export function cancelled(): CancelledResult {
  return { kind: CancellableResultKind.Cancelled };
}

export function failure(error: Error): FailureResult {
  return { kind: CancellableResultKind.Failure, error };
}

export function success<T>(value: T): SuccessResult<T> {
  return { kind: CancellableResultKind.Success, value };
}

// Type checkers
export function isCancelled<T>(cancellableResult: CancellableResult<T>): cancellableResult is CancelledResult {
  return cancellableResult.kind === CancellableResultKind.Cancelled;
}

export function isFailure<T>(cancellableResult: CancellableResult<T>): cancellableResult is FailureResult {
  return cancellableResult.kind === CancellableResultKind.Failure;
}

export function isSuccess<T>(cancellableResult: CancellableResult<T>): cancellableResult is SuccessResult<T> {
  return cancellableResult.kind === CancellableResultKind.Success;
}
