import * as Verify from "Utils/Verify";
import { DateDisplayTimeZone } from "DateDisplayTimeZone";

export function formatTimeHHMM(date: Date, displayTimeZone: DateDisplayTimeZone): string {
  return getIsoDateString(date, displayTimeZone).split("T")[1].split(":").slice(0, 2).join(":");
}

export function formatTimeHHMMSS(date: Date, displayTimeZone: DateDisplayTimeZone): string {
  return getIsoDateString(date, displayTimeZone).split("T")[1].split(".")[0];
}

export function formatDate(date: Date, displayTimeZone: DateDisplayTimeZone): string {
  const dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    timeZone: getTimeZone(displayTimeZone),
  });
  return dateFormatter.format(date);
}

export function formatTimestamp(date: Date, displayTimeZone: DateDisplayTimeZone): string {
  const isoDateString: string = getIsoDateString(date, displayTimeZone);
  const dateString: string = isoDateString.split("T")[0];
  const timeString: string = isoDateString.split("T")[1].split(".")[0];
  return `${dateString} ${timeString}`;
}

export function getTimeZone(displayTimeZone: DateDisplayTimeZone): string {
  switch (displayTimeZone) {
    case DateDisplayTimeZone.CurrentTimeZone:
      return Intl.DateTimeFormat().resolvedOptions().timeZone;

    case DateDisplayTimeZone.UTC:
      return "UTC";

    default:
      Verify.isNever(displayTimeZone);
  }
}

export function getTimeZoneOffset(displayTimeZone: DateDisplayTimeZone): number {
  switch (displayTimeZone) {
    case DateDisplayTimeZone.CurrentTimeZone:
      return new Date().getTimezoneOffset() * 60000;

    case DateDisplayTimeZone.UTC:
      return 0;

    default:
      Verify.isNever(displayTimeZone);
  }
}

export function getHours(date: Date, displayTimeZone: DateDisplayTimeZone): number {
  switch (displayTimeZone) {
    case DateDisplayTimeZone.CurrentTimeZone:
      return date.getHours();

    case DateDisplayTimeZone.UTC:
      return date.getUTCHours();

    default:
      Verify.isNever(displayTimeZone);
  }
}

// The function getIsoDateString always returns a string in the format YYYY-MM-DDTHH:mm:ss.sss
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
function getIsoDateString(date: Date, displayTimeZone: DateDisplayTimeZone): string {
  return new Date(date.valueOf() - getTimeZoneOffset(displayTimeZone)).toISOString().slice(0, -1);
}
