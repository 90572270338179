import React from "react";
import * as StyleUtils from "Utils/StyleUtils";

export function SignInButton(props: SignInButtonProps): React.ReactElement {
  return (
    <button aria-label={props.label} className={StyleUtils.mergeClassNames("border text-base", props.className)} onClick={props.onClick}>
      <span className="flex justify-center">{props.label}</span>
    </button>
  );
}

export interface SignInButtonProps {
  className?: string;
  label: string;

  onClick: () => void;
}
