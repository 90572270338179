import React from "react";
import { XIcon } from "lucide-react";

import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
import { TextField } from "DesignComponents/TextField";

export function NamespaceCreationDialog(props: NamespaceCreationDialogProps): React.ReactElement {
  const [name, setName] = React.useState<string>("");
  return (
    <div className="relative min-w-[25%] bg-zinc-900 p-10 text-sm rounded-lg shadow-lg">
      <XIcon
        className={StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white")}
        onClick={() => props.closeModal(undefined)}
      />
      <div className="flex flex-col space-y-6 justify-between h-full">
        <span className="flex flex-row mt-3 space-x-1 items-center">
          <span className="text-white">Provide a name for the new namespace:</span>
        </span>
        <div className="flex flex-row space-x-4">
          <div className="grow">
            <TextField onKeyDown={onTextFieldKeyDown} autoFocus classNames={{ input: "h-8 px-2" }} value={name} onChange={setName} />
          </div>
          <Button className="h-8 px-4 outline-0 rounded" label="Create" onClick={() => props.closeModal(name)} />
        </div>
      </div>
    </div>
  );

  function onTextFieldKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter") {
      props.closeModal(name);
    }
  }
}

export interface NamespaceCreationDialogProps {
  closeModal: (name: string | undefined) => void;
}
