import * as React from "react";
import * as StyleUtils from "Utils/StyleUtils";

export function Toggle(props: ToggleProps): React.ReactNode {
  return (
    <label className={StyleUtils.mergeClassNames("inline-flex items-center cursor-pointer", props.className)}>
      <input checked={props.checked} className="sr-only peer" onChange={(event) => props.onChange(event.target.checked)} type="checkbox" />
      <div
        className={StyleUtils.mergeClassNames(
          "relative w-7 h-4 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-sky-800 rounded-full peer bg-gray-700 border-gray-600",
          "after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all",
          "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white",
        )}
      />
    </label>
  );
}

export interface ToggleProps {
  checked: boolean;
  className?: string;

  onChange: (value: boolean) => void;
}
