export type AsyncValue<T> = UninitializedValue | PendingValue<T> | FulfilledValue<T>;

export type UninitializedValue = { kind: AsyncValueKind.Uninitialized };
export type PendingValue<T> = { kind: AsyncValueKind.Pending; promise: Promise<T> };
export type FulfilledValue<T> = { kind: AsyncValueKind.Fulfilled; value: T };

export enum AsyncValueKind {
  Fulfilled = "Fulfilled",
  Pending = "Pending",
  Uninitialized = "Uninitialized",
}
