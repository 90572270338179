import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { DeleteIcon, FilterXIcon } from "lucide-react";

import * as CustomHooks from "CustomHooks";
import * as DateUtils from "DateUtils";
import * as EventLogger from "EventLogger";
import * as JsonUtils from "JsonUtils";
import * as MathUtils from "Utils/MathUtils";
import * as QueryUtils from "QueryUtils";
import * as RequestGraphConstants from "RequestGraphConstants";
import * as StrictUtils from "Utils/StrictUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import { Clause } from "Clause";
import { ClauseEditor, ClauseEditorImperativeProps } from "ClauseEditor";
import { DateDisplayTimeZone } from "DateDisplayTimeZone";
import { GraphQuery } from "GraphQuery";
import { GraphQueryColumnName } from "GraphQueryColumnName";
import { GridColumnDefinition } from "GridColumnDefinition";
import { GridQuery } from "GridQuery";
import { LazyGrid } from "LazyGrid";
import { QueryResult, QueryResultRow } from "QueryResult";
import { RequestDetailsSidePanel } from "RequestDetailsSidePanel";
import { RequestGraph } from "RequestGraph";
import { Spinner } from "DesignComponents/Spinner";
import { SubtraceEventKind } from "SubtraceEventKind";
import { QueryManager } from "QueryManager";
import { Toggle } from "Toggle";
import { UrlFilterState } from "UrlFilterState";
import { ChromeDevToolsPanel } from "ChromeDevToolsPanel";

const filterSearchParamName: string = "filter";

export function RequestsPage(props: RequestPageProps): React.ReactNode {
  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { namespaceId } = currentNamespace;

  const [searchParams, setSearchParams] = ReactRouterDom.useSearchParams();
  const initialFilterSearchParam: string | null = searchParams.get(filterSearchParamName);

  const [gridColumnNames, setGridColumnNames] = React.useState<string[]>(QueryUtils.GRID_QUERY_DEFAULT_COLUMNS.map((field) => SubtraceEvent.knownFieldsToJSON(field)));
  const clauseEditorRef: React.MutableRefObject<ClauseEditorImperativeProps | null> = React.useRef(null);

  let urlFilterState: UrlFilterState | undefined = undefined;
  try {
    if (initialFilterSearchParam != null) {
      urlFilterState = JsonUtils.parse(atob(initialFilterSearchParam));
    }
  } catch {
    // Do nothing, the filter is likely not valid JSON
  }

  const [displayTimeZone, setDisplayTimeZone] = React.useState<DateDisplayTimeZone>(DateDisplayTimeZone.UTC);

  const initialGraphGlobalRightTimestamp: number =
    urlFilterState?.globalTimestampFilter?.upperBoundTimestamp ??
    MathUtils.roundUpToGranularity(Date.now(), RequestGraphConstants.DEFAULT_GRANULARITY, DateUtils.getTimeZoneOffset(displayTimeZone));
  const initialGraphGlobalLeftTimestamp: number =
    urlFilterState?.globalTimestampFilter?.lowerBoundTimestamp ??
    initialGraphGlobalRightTimestamp - RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * RequestGraphConstants.DEFAULT_GRANULARITY;
  const initialGraphFocusedRightTimestamp: number = urlFilterState?.focusedTimestampFilter?.upperBoundTimestamp ?? initialGraphGlobalRightTimestamp;
  const initialGraphFocusedLeftTimestamp: number = urlFilterState?.focusedTimestampFilter?.lowerBoundTimestamp ?? initialGraphGlobalLeftTimestamp;

  const [evaluatingGridQuery, setEvaluatingGridQuery] = React.useState<GridQuery | undefined>({
    clauses: urlFilterState?.clauses ?? [],
    timestampFilter: {
      lowerBoundTimestamp: initialGraphFocusedLeftTimestamp,
      upperBoundTimestamp: initialGraphFocusedRightTimestamp,
    },
  });
  const [appliedGridQuery, setAppliedGridQuery] = React.useState<GridQuery | undefined>(undefined);
  const [gridQueryResult, setGridQueryResult] = React.useState<QueryResult | undefined>(undefined);
  const evaluatingGridQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const [graphCountByTimestamp, setGraphCountByTimestamp] = React.useState<Map<number, number>>(new Map());
  const [graphGlobalLeftTimestamp, setGraphGlobalLeftTimestamp] = React.useState<number>(initialGraphGlobalLeftTimestamp);
  const [graphGlobalRightTimestamp, setGraphGlobalRightTimestamp] = React.useState<number>(initialGraphGlobalRightTimestamp);
  const [graphFocusedLeftTimestamp, setGraphFocusedLeftTimestamp] = React.useState<number>(initialGraphFocusedLeftTimestamp);
  const [graphFocusedRightTimestamp, setGraphFocusedRightTimestamp] = React.useState<number>(initialGraphFocusedRightTimestamp);
  const [evaluatingGraphQuery, setEvaluatingGraphQuery] = React.useState<GraphQuery | undefined>({
    clauses: urlFilterState?.clauses ?? [],
    displayTimeZone,
    timestampFilter: {
      lowerBoundTimestamp: initialGraphGlobalLeftTimestamp,
      upperBoundTimestamp: initialGraphGlobalRightTimestamp,
    },
  });
  const evaluatingGraphQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const [sidePanelState, setSidePanelState] = React.useState<SidePanelState>({ isOpen: false });

  const queryManager: QueryManager = QueryManager.getInstance();
  queryManager.setOnQueryEvaluated(onQueryEvaluated);

  React.useEffect(
    function evaluateGridQuery(): void {
      if (evaluatingGridQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingGridQueryId.current = queryId;

      queryManager.queueEvaluation(namespaceId, QueryUtils.toGridSqlQuery(namespaceId, evaluatingGridQuery), queryId);
    },
    [evaluatingGridQuery, namespaceId, queryManager],
  );

  React.useEffect(
    function evaluateGraphQuery(): void {
      if (evaluatingGraphQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingGraphQueryId.current = queryId;

      queryManager.queueEvaluation(namespaceId, QueryUtils.toGraphSqlQuery(namespaceId, evaluatingGraphQuery), queryId);
    },
    [evaluatingGraphQuery, namespaceId, queryManager],
  );

  const columnDefinitions: GridColumnDefinition[] = gridColumnNames.map((columnName) => ({ columnName })) ?? [];
  const allGridRows: QueryResultRow[] = gridQueryResult?.data ?? [];

  return (
    <div className="flex w-full h-screen justify-center">
      <div className="flex flex-col items-center w-full h-full px-4 pt-6 space-y-8">
        <div className="flex flex-row w-full">
          <div className="w-full flex flex-col max-w-[90%] space-y-8">
            <RequestGraph
              displayTimeZone={displayTimeZone}
              focusedLeftTimestamp={graphFocusedLeftTimestamp}
              focusedRightTimestamp={graphFocusedRightTimestamp}
              globalLeftTimestamp={graphGlobalLeftTimestamp}
              globalRightTimestamp={graphGlobalRightTimestamp}
              countByTimestamp={graphCountByTimestamp}
              isLoading={evaluatingGraphQuery !== undefined}
              keepGlobalRangeFixed={false}
              onFocusedTimeRangeChanged={onGraphFocusedTimeRangeChanged}
              onGlobalTimeRangeChanged={onGraphGlobalRangeChanged}
            />
            <div className="w-full flex flex-col space-y-2">
              <div className="w-full flex px-8 space-x-2">
                <ClauseEditor queryResult={gridQueryResult ? { ...gridQueryResult, data: allGridRows } : undefined} onClauseCommitted={onClauseCommitted} ref={clauseEditorRef} />
                <button className="rounded-sm w-8 flex flex-row justify-center items-center hover:brightness-110" onClick={deleteAllClauses} title="Clear filters">
                  <FilterXIcon className="text-zinc-400" size={15} />
                </button>
                {/* TODO: "Learn more" linking to docs  */}
              </div>
              <div className="px-8">{renderClauses()}</div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 text-zinc-500 text-xs">
            <div className="flex flex-row space-x-2 ">
              <span>UTC</span>
              <Toggle
                className="self-start"
                checked={displayTimeZone === DateDisplayTimeZone.CurrentTimeZone}
                onChange={(value) => setDisplayTimeZone(value ? DateDisplayTimeZone.CurrentTimeZone : DateDisplayTimeZone.UTC)}
              />
              <span>Local</span>
            </div>
            <div className="flex flex-row space-x-2">
              <span>Grid</span>
              <Toggle className="self-start" checked={props.isDevtoolsUIEnabled} onChange={props.setIsDevtoolsUIEnabled} />
              <span>Devtools</span>
            </div>
          </div>
        </div>
        {props.isDevtoolsUIEnabled ? (
          <ChromeDevToolsPanel isLoading={evaluatingGridQuery != null} queryResult={gridQueryResult} />
        ) : (
          <LazyGrid
            fallback={
              <div className="px-8 h-full flex justify-center items-center">
                <Spinner className="scale-[200%]" />
              </div>
            }
            displayTimeZone={displayTimeZone}
            isLoading={evaluatingGridQuery !== undefined}
            onRowDoubleClicked={onRowDoubleClicked}
            queryResult={gridQueryResult}
            columnDefinitions={columnDefinitions}
          />
        )}
      </div>
      {sidePanelState.isOpen ? (
        <RequestDetailsSidePanel
          closeSidePanel={() => {
            setSidePanelState({ isOpen: false });
            EventLogger.logEvent(SubtraceEventKind.GridColumnNamesChanged, { grid_column_names: gridColumnNames.join(",") });
          }}
          gridColumnNames={gridColumnNames}
          rowData={sidePanelState.rowData}
          setGridColumnNames={setGridColumnNames}
        />
      ) : null}
    </div>
  );

  function deleteAllClauses(): void {
    if (evaluatingGraphQuery == null && evaluatingGridQuery == null && appliedGridQuery?.clauses.length === 0) {
      return;
    }

    setEvaluatingGridQuery({
      clauses: [],
      timestampFilter: {
        lowerBoundTimestamp: graphFocusedLeftTimestamp,
        upperBoundTimestamp: graphFocusedRightTimestamp,
      },
    });
    setEvaluatingGraphQuery({
      clauses: [],
      displayTimeZone,
      timestampFilter: {
        lowerBoundTimestamp: graphGlobalLeftTimestamp,
        upperBoundTimestamp: graphGlobalRightTimestamp,
      },
    });
  }

  function onGraphGlobalRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphGlobalLeftTimestamp(startTimestamp);
    setGraphGlobalRightTimestamp(endTimestamp);
    setEvaluatingGraphQuery({
      clauses: (evaluatingGridQuery ?? appliedGridQuery)?.clauses ?? [],
      displayTimeZone,
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onGraphFocusedTimeRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphFocusedLeftTimestamp(startTimestamp);
    setGraphFocusedRightTimestamp(endTimestamp);
    setEvaluatingGridQuery({
      clauses: (evaluatingGridQuery ?? appliedGridQuery)?.clauses ?? [],
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onClauseCommitted(clause: Clause): void {
    const baseGridQuery: GridQuery | undefined = evaluatingGridQuery ?? appliedGridQuery;
    const clausesToDisplay: Clause[] = baseGridQuery?.clauses ?? [];
    const clauseIndex: number = clausesToDisplay.findIndex((_clause) => _clause.clauseId === clause.clauseId);
    if (clauseIndex !== -1) {
      // Editing an existing clause
      const clauses: Clause[] = [...clausesToDisplay.slice(0, clauseIndex), clause, ...clausesToDisplay.slice(clauseIndex + 1)];
      setEvaluatingGridQuery({
        clauses,
        timestampFilter: baseGridQuery?.timestampFilter,
      });
      setEvaluatingGraphQuery({
        clauses,
        displayTimeZone,
        timestampFilter: {
          lowerBoundTimestamp: graphGlobalLeftTimestamp,
          upperBoundTimestamp: graphGlobalRightTimestamp,
        },
      });
    } else {
      // Adding a new clause
      const clauses: Clause[] = [...clausesToDisplay, clause];
      setEvaluatingGridQuery({
        clauses,
        timestampFilter: baseGridQuery?.timestampFilter,
      });
      setEvaluatingGraphQuery({
        clauses,
        displayTimeZone,
        timestampFilter: {
          lowerBoundTimestamp: graphGlobalLeftTimestamp,
          upperBoundTimestamp: graphGlobalRightTimestamp,
        },
      });
    }
  }

  function onClauseClicked(clause: Clause): void {
    clauseEditorRef.current?.setClauseBeingEdited(clause);
  }

  function onQueryEvaluated(queryId: string, queryResult: QueryResult): void {
    if (queryId === evaluatingGridQueryId.current) {
      setGridQueryResult(queryResult);
      setAppliedGridQuery(evaluatingGridQuery);
      setEvaluatingGridQuery(undefined);
      updateUrlFilter({ clauses: evaluatingGridQuery?.clauses, focusedTimestampFilter: evaluatingGridQuery?.timestampFilter });
    } else if (queryId === evaluatingGraphQueryId.current) {
      const countsByBinnedTimestamp: Map<number, number> = new Map(
        queryResult.data.map((row) => {
          let dateString: string = StrictUtils.ensureDefined(row[GraphQueryColumnName.BinnedTimestamp]);
          // Ensure that we interpret this as a UTC timestamp.
          dateString = dateString.endsWith("Z") ? dateString : dateString + "Z";
          const count: number = parseInt(StrictUtils.ensureDefined(row[GraphQueryColumnName.Count]));
          return [new Date(dateString).valueOf(), count];
        }),
      );

      setGraphCountByTimestamp(countsByBinnedTimestamp);
      setEvaluatingGraphQuery(undefined);
      updateUrlFilter({ clauses: evaluatingGraphQuery?.clauses, globalTimestampFilter: evaluatingGraphQuery?.timestampFilter });
    }
    return;
  }

  function renderClauses(): React.ReactNode {
    const baseGridQuery: GridQuery | undefined = evaluatingGridQuery ?? appliedGridQuery;
    const clausesToDisplay: Clause[] = baseGridQuery?.clauses ?? [];

    return (
      <div className="space-x-2 flex flex-row flex-wrap font-mono">
        {clausesToDisplay.map((clause) => (
          <div
            key={clause.clauseId}
            className="px-3 py-[6px] my-1 text-[11px] leading-[11px] font-medium text-zinc-400 bg-zinc-900/70 outline outline-[1px] outline-zinc-800/70 rounded space-x-3 flex flex-row hover:brightness-[1.15] cursor-pointer"
            onClick={() => onClauseClicked(clause)}
            title={clause.clauseText}
          >
            <span className="select-none">{clause.clauseText}</span>
            <span
              className="hover:text-zinc-300"
              onClick={(event) => {
                event.stopPropagation();

                const clauses: Clause[] = clausesToDisplay.filter((_clause) => _clause.clauseId !== clause.clauseId);
                setEvaluatingGridQuery({
                  clauses: clausesToDisplay.filter((_clause) => _clause.clauseId !== clause.clauseId),
                  timestampFilter: baseGridQuery?.timestampFilter,
                });
                setEvaluatingGraphQuery({
                  clauses,
                  displayTimeZone,
                  timestampFilter: {
                    lowerBoundTimestamp: graphGlobalLeftTimestamp,
                    upperBoundTimestamp: graphGlobalRightTimestamp,
                  },
                });
              }}
            >
              <DeleteIcon className="-my-[1px] h-[13px] w-[13px]" />
            </span>
          </div>
        ))}
      </div>
    );
  }

  function onRowDoubleClicked(row: QueryResultRow): void {
    setSidePanelState({ isOpen: true, rowData: row });
  }

  function updateUrlFilter(urlFilterState?: Partial<UrlFilterState>): void {
    const updatedUrlFilterState: UrlFilterState = {
      clauses: appliedGridQuery?.clauses,
      focusedTimestampFilter: { lowerBoundTimestamp: graphFocusedLeftTimestamp, upperBoundTimestamp: graphFocusedRightTimestamp },
      globalTimestampFilter: { lowerBoundTimestamp: graphGlobalLeftTimestamp, upperBoundTimestamp: graphGlobalRightTimestamp },
      ...urlFilterState,
    };

    setSearchParams(
      (params) => {
        params.set(filterSearchParamName, btoa(JsonUtils.stringify(updatedUrlFilterState)));
        return params;
      },
      { replace: true },
    );
  }
}

export interface RequestPageProps {
  isDevtoolsUIEnabled: boolean;

  setIsDevtoolsUIEnabled: (value: boolean) => void;
}

type SidePanelState =
  | {
      isOpen: true;
      rowData: QueryResultRow;
    }
  | {
      isOpen: false;
    };
