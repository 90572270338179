// JSON.stringify cannot serialize BigInts by default [1]. To fix this, add custom
// serialization and deserialization logic. Also do the same things for dates since they aren't
// roundtripped correctly by default [2]. Note that this will only work if we never see an object
// that has a key `$$bigint` or `$$date`.
//
// [1] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
// [2] https://stackoverflow.com/a/68990190

export function stringify(value: any): string {
  return JSON.stringify(value, (_key, value) => {
    if (typeof value === "bigint") {
      return { $$bigint: value.toString() };
    }
    if (isDate(value)) {
      return { $$date: value.toString() };
    }
    return value;
  });
}

export function parse<T>(text: string): T {
  return JSON.parse(text, (_key, value) => {
    if (value !== null && typeof value === "object" && "$$bigint" in value && typeof value.$$bigint === "string") {
      return BigInt(value.$$bigint);
    }
    if (value !== null && typeof value === "object" && "$$date" in value && typeof value.$$date === "string") {
      return new Date(value.$$date);
    }
    return value;
  });
}

// Prefer this over the instanceof operator, since it works across iframe boundaries.
// See here for more details: https://stackoverflow.com/a/643827
function isDate(object: any): object is Date {
  return Object.prototype.toString.call(object) === "[object Date]";
}
