import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";

// The default org is the first org the user has access to.
export async function listOrgs(signal?: AbortSignal): Promise<Api.ListOrgs_Item[]> {
  try {
    const listOrgsRequest: Api.ListOrgs_Request = {};
    const listOrgsResponse: Response = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, /* options */ { signal });
    await ApiUtils.assertStatus(listOrgsResponse, 200);
    const { orgs }: Api.ListOrgs_Response = await listOrgsResponse.json();
    return orgs;
  } catch (error: unknown) {
    if (signal?.aborted) {
      // Do nothing, the API call was canceled
    }

    throw error;
  }
}
