import { NumericalFilter } from "NumericalFilter";
import { TextFilter } from "TextFilter";
import { TimestampFilter } from "TimestampFilter";

export type Clause = {
  clauseId: string;
  columnName: string;
  clauseText: string;
} & (
  | {
      kind: ClauseKind.Number;
      filter: NumericalFilter;
    }
  | {
      kind: ClauseKind.String;
      filter: TextFilter;
    }
  | {
      kind: ClauseKind.Timestamp;
      filter: TimestampFilter;
    }
);

export const enum ClauseKind {
  String = "String",
  Number = "Number",
  Timestamp = "Timestamp",
}
