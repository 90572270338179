export type NumericalFilter = {
  kind:
    | NumericalFilterKind.Equals
    | NumericalFilterKind.GreaterThan
    | NumericalFilterKind.GreaterThanOrEquals
    | NumericalFilterKind.LesserThan
    | NumericalFilterKind.LesserThanOrEquals
    | NumericalFilterKind.NotEquals;
  value: bigint;
};

export const enum NumericalFilterKind {
  Equals = "Equals",
  GreaterThan = "GreaterThan",
  GreaterThanOrEquals = "GreaterThanOrEquals",
  LesserThan = "LesserThan",
  LesserThanOrEquals = "LesserThanOrEquals",
  NotEquals = "NotEquals",
}
