import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { Logo } from "Logo";
import { Spinner } from "DesignComponents/Spinner";
import { TextField } from "DesignComponents/TextField";
import { Button } from "DesignComponents/Button";
import { LogOut, Sprout, Rocket } from "lucide-react";

// "user@example.com" -> "example"
function getDefaultNamespaceName(email: string): string {
  const domain = email.substring(email.indexOf("@") + 1);
  return domain.substring(0, domain.indexOf("."));
}

export function OnboardingPage(): React.ReactElement {
  const currentUser = CustomHooks.useCurrentUser();
  const [isUserOnboarded, setIsUserOnboarded] = React.useState<boolean | undefined>(undefined);
  const [orgToken, setOrgToken] = React.useState<string | undefined>(undefined);
  const [namespaceName, setNamespaceName] = React.useState<string | undefined>(undefined);
  const [isPending, setIsPending] = React.useState(false);
  const [hasActiveWorker, setHasActiveWorker] = React.useState<boolean | undefined>(undefined);
  const [intervalId, setIntervalId] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (isUserOnboarded === undefined) {
      ApiUtils.post<Api.ListOrgs_Request>("/api/ListOrgs", {}, {}).then(async (response: Response) => {
        if (response.status === 403) {
          document.location = "/login";
          return;
        } else {
          ApiUtils.assertStatus(response, 200);
        }
        const { orgs }: Api.ListOrgs_Response = await response.json();
        setIsUserOnboarded(orgs.length > 0);
      });
    }
  }, [isUserOnboarded]);

  React.useEffect(() => {
    if (orgToken === undefined || intervalId === -1) {
      return;
    }

    if (hasActiveWorker) {
      if (intervalId !== undefined) {
        setIntervalId((intervalId) => {
          clearInterval(intervalId);
          return -1;
        });
      }
      return;
    }

    if (intervalId === undefined) {
      setIntervalId(
        setInterval(async () => {
          const response: Response = await ApiUtils.post<Api.HasActiveWorker_Request>("/api/HasActiveWorker", {}, {});
          if (response.status !== 200) {
            setIntervalId((intervalId) => {
              clearInterval(intervalId);
              return -1;
            });
          }
          ApiUtils.assertStatus(response, 200);

          const { hasActiveWorker }: Api.HasActiveWorker_Response = await response.json();
          setHasActiveWorker(hasActiveWorker);
        }, 1000),
      );
    }
  }, [orgToken, hasActiveWorker, intervalId]);

  return (
    <div className="w-full flex grow justify-center items-begin pt-8">
      <div className="w-full max-w-[25rem]">
        <div className="w-full h-full flex flex-col align-middle space-y-6 bg-zinc-900/20 border border-zinc-900/50 rounded-lg p-6">
          {orgToken === undefined ? renderOnboardNewUser() : renderOnboardWorker(orgToken)}
        </div>
      </div>
    </div>
  );

  async function onboardNewUser(namespaceName: string): Promise<void> {
    namespaceName = namespaceName.toLowerCase();
    if (/[^a-z0-9-]/.test(namespaceName)) {
      alert("Namespace may contain only alphabets (a-z), digits (0-9), and hyphen.");
      return;
    }

    setIsPending(true);

    const response: Response = await ApiUtils.post<Api.OnboardNewUser_Request>("/api/OnboardNewUser", { namespaceName }, {});
    if (response.status !== 200) {
      setIsPending(false);
      alert(`Something went wrong, sorry about that. If the problem persists, please contact support@subtrace.dev with this error ID: ${response.headers.get("x-subtrace-id")}.`);
      return;
    }

    const { orgToken }: Api.OnboardNewUser_Response = await response.json();
    setOrgToken(orgToken);
    setIsPending(false);
  }

  function renderLoggedInAs(email: string): React.ReactElement {
    return (
      <div className="flex justify-center space-x-4">
        <div className="text-center text-zinc-500 text-xs">
          <span>Logged in as</span> <span className="font-medium text-zinc-400">{email}</span>
        </div>

        <div className="text-center text-zinc-500 text-xs">
          <button
            onClick={() => (document.location = "/api/LogOut")}
            className="-m-2 w-8 h-8 rounded-full text-zinc-500 hover:text-zinc-300 cursor-pointer flex justify-center items-center group relative"
          >
            <div className="w-[14px] h-[14px] flex items-center justify-center">
              <LogOut />
            </div>
            <div className="whitespace-pre hidden group-hover:flex absolute -top-3 left-[50%] translate-x-[-50%] text-center text-[10px] justify-center items-center">
              <span>Logout</span>
            </div>
          </button>
        </div>
      </div>
    );
  }

  function renderOnboardNewUser(): React.ReactElement {
    if (currentUser === undefined || isUserOnboarded === undefined) {
      return <Spinner />;
    }

    if (!currentUser.isLoggedIn) {
      document.location = "/login";
      return <React.Fragment />;
    }

    if (isUserOnboarded) {
      document.location = "/dashboard";
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className="flex justify-center items-center grayscale opacity-[0.75]">
          <Logo className="w-[8rem]" />
        </div>

        <div className="flex justify-center items-center text-zinc-700">
          <Sprout strokeWidth={0.75} size={128} />
        </div>

        {renderLoggedInAs(currentUser.email)}

        <div className="text-center text-zinc-300 text-md font-medium">What should we call your namespace?</div>

        <TextField
          type="text"
          placeholder={getDefaultNamespaceName(currentUser.email)}
          value={namespaceName ?? getDefaultNamespaceName(currentUser.email)}
          onChange={setNamespaceName}
        />

        <div className="text-center text-zinc-500 text-xs">
          <span>You can change this later in the dashboard.</span>
        </div>

        <div className="flex justify-center text-zinc-300 text-md font-medium">
          <Button
            className="w-fit"
            disabled={isPending || namespaceName === ""}
            label="Continue"
            onClick={() => onboardNewUser(namespaceName ?? getDefaultNamespaceName(currentUser.email))}
            showArrow
          />
        </div>
      </React.Fragment>
    );
  }

  function renderOnboardWorker(orgToken: string): React.ReactElement {
    if (currentUser === undefined) {
      return <Spinner />;
    } else if (!currentUser.isLoggedIn) {
      document.location = "/login";
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <div className="flex justify-center items-center grayscale opacity-[0.75]">
          <Logo className="w-[8rem]" />
        </div>

        {renderLoggedInAs(currentUser.email)}

        <div className="text-center text-zinc-300 text-md font-medium">Let's set up your database</div>

        <div className="text-left text-zinc-400 text-sm leading-[1.50]">
          <span>
            Open a terminal session and run the following command to start a <span className="font-medium">database worker</span> using Docker.
          </span>{" "}
          <span>We've already prefilled it with an API token for you.</span>
        </div>

        <pre className="text-[11px] text-zinc-300/90 px-4 py-3 bg-zinc-800/75 outline outline-[1px] outline-zinc-800 cursor-pointer hover:brightness-[1.1] rounded select-all overflow-scroll overflow-hide-scrollbar">
          docker run -d \<br />
          &nbsp; -e SUBTRACE_TOKEN={orgToken} \<br />
          &nbsp; -v subtrace:/var/lib/clickhouse \<br />
          &nbsp; <span className="font-medium text-zinc-200">subtrace.dev/worker:latest</span>
        </pre>

        {/* FAQ */}
        <div className="space-y-2 px-2">
          <details className="text-zinc-600 text-xs space-y-2">
            <summary className="cursor-pointer hover:brightness-[1.1] font-medium">How do I install Docker?</summary>
            <pre className="text-[10px] text-zinc-300/90 px-4 py-3 bg-zinc-800/75 outline outline-[1px] outline-zinc-800 cursor-pointer hover:brightness-[1.1] rounded select-all overflow-scroll overflow-hide-scrollbar">
              curl -fsSL https://get.docker.com | sh
            </pre>
          </details>

          <details className="text-zinc-600 text-xs space-y-2">
            <summary className="cursor-pointer hover:brightness-[1.1] font-medium">What are the system requirements?</summary>
            <p className="text-zinc-400/70">We recommended setting up the worker on a server with at least 4 GB of memory and 20 GB of free disk space.</p>
          </details>

          <details className="text-zinc-600 text-xs space-y-2">
            <summary className="cursor-pointer hover:brightness-[1.1] font-medium">Do you offer a hosted database as a service?</summary>
            <p className="text-zinc-400/70">
              <span>Not at the moment, but we're working on this and we'd love to understand your usecase. Please let us know by joining our</span>{" "}
              <a className="text-zinc-400 hover:brightness-[1.1] font-medium" href="https://subtrace.dev/discord">
                discord
              </a>{" "}
              <span>or by</span>{" "}
              <a className="text-zinc-400 hover:brightness-[1.1] font-medium" href="mailto:support@subtrace.dev">
                sending us an email
              </a>
              .
            </p>
          </details>
        </div>

        <div className="bg-zinc-800/25 rounded px-8 py-8 outline outline-[1px] outline-zinc-800/50 flex flex-col items-center justify-center space-y-4">
          {hasActiveWorker ? (
            <React.Fragment>
              <Rocket className="text-zinc-400" strokeWidth={0.75} size={32} />
              <span className="text-zinc-600 text-xs font-medium">Connected!</span>
              {/* TODO: show the hostname (send it in ListOpenTunnels?) */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="rounded-full h-[12px] w-[12px] bg-zinc-700 flex justify-center items-center animate-ping">
                <div className="rounded-full h-[6px] w-[6px] bg-zinc-600 flex justify-center items-center animate-pulse" />
              </div>
              <span className="text-zinc-600 text-xs font-medium">Waiting&hellip;</span>
            </React.Fragment>
          )}
        </div>

        <div className="flex flex-col items-center justify-center text-zinc-300 space-y-3">
          <Button className="w-fit" disabled={hasActiveWorker !== true} label="Finish" onClick={() => (document.location = "/dashboard")} showArrow />
          {hasActiveWorker ? null : (
            <a className="w-fit text-zinc-500 text-xs font-medium hover:brightness-[1.1]" href="/dashboard">
              I'll do this later
            </a>
          )}
        </div>
      </React.Fragment>
    );
  }
}
