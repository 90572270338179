import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";

export async function getNamespaces(orgId: string, signal?: AbortSignal): Promise<Api.ListNamespaces_Item[]> {
  try {
    const listNamespacesRequest: Api.ListNamespaces_Request = { orgId };
    const listNamespacesResponse: Response = await ApiUtils.post(
      "/api/ListNamespaces",
      listNamespacesRequest,
      /* options */ {
        signal,
        subtraceTags: { org_id: orgId },
      },
    );
    await ApiUtils.assertStatus(listNamespacesResponse, 200);

    const { namespaces }: Api.ListNamespaces_Response = await listNamespacesResponse.json();
    ArrayUtils.assertLengthAtLeast(namespaces, 1);
    return namespaces;
  } catch (error: unknown) {
    if (signal?.aborted) {
      // Do nothing, the API call was canceled
    }

    throw error;
  }
}
