import { mergeClassNames } from "Utils/StyleUtils";
import React from "react";

export function Spinner(props: SpinnerProps): React.ReactElement {
  return (
    <div className={mergeClassNames("flex justify-center items-center", props.className)}>
      <div className="relative w-6 h-6">
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className="absolute bg-white w-[2px] h-[7px] rounded transform origin-bottom left-[11px] top-[5px] animate-pulse"
            style={{
              transform: `rotate(${index * 45}deg) translateY(-3px)`,
              animationDelay: `${((index - 8) * 2000) / 8}ms`,
            }}
          />
        ))}
      </div>
    </div>
  );
}

export interface SpinnerProps {
  className?: string;
}
