export type TextFilter = {
  kind: TextFilterKind;
  value: string;
};

export const enum TextFilterKind {
  DoesNotEqual = "DoesNotEqual",
  Equals = "Equals",
  Like = "Like",
  NotLike = "NotLike",
}
