export function isSafari(): boolean {
  return getBrowserName() === "Safari";
}

// This is really fragile, since user agent strings can be spoofed.
// Ideally we'd perform feature detection instead of browser detection,
// but it's not clear what feature (or lack thereof) is problematic
// on Safari so we just do this for now.
// Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#which_part_of_the_user_agent_contains_the_information_you_are_looking_for
function getBrowserName(): "Edge" | "Opera" | "Chrome" | "Safari" | "Firefox" | "unknown" {
  const browserInfo: string = navigator.userAgent;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    return "Opera";
  } else if (browserInfo.includes("Edg")) {
    return "Edge";
  } else if (browserInfo.includes("Chrome")) {
    return "Chrome";
  } else if (browserInfo.includes("Safari")) {
    return "Safari";
  } else if (browserInfo.includes("Firefox")) {
    return "Firefox";
  } else {
    return "unknown";
  }
}
