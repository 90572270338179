import React from "react";

import * as StyleUtils from "Utils/StyleUtils";

export function ExternalLink(props: ExternalLinkProps): React.ReactElement {
  return (
    <a
      className={StyleUtils.mergeClassNames(
        props.showAsButton ? "text-sm text-zinc-200 font-medium rounded bg-sky-700/95 hover:bg-sky-700 group" : "rounded font-medium text-sm text-zinc-300/95 hover:text-zinc-300",
        props.className,
      )}
      href={props.target}
      {...(props.openInNewTab
        ? {
            target: "_blank",
            rel: "noreferrer",
          }
        : {})}
    >
      {props.label}
      {props.showArrow ? <span className="inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]">&rarr;</span> : null}
    </a>
  );
}

export interface ExternalLinkProps {
  className?: string;
  label: string;
  openInNewTab?: boolean;
  showArrow?: true;
  showAsButton?: boolean;
  target: string;
}
