import * as React from "react";
import { Loader2, Trash } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { DialogManager } from "DialogManager";
import { GetApiTokenDialog } from "GetApiTokenDialog";
import { InsufficientPermissionsDialog } from "InsufficientPermissionsDialog";
import { DeleteConfirmationDialog } from "DeleteConfirmationDialog";

export function TokensPage(): React.ReactNode {
  const dialogManager: DialogManager = CustomHooks.useDialogManager();
  const { orgId } = CustomHooks.useCurrentOrg();
  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { namespaceId } = currentNamespace;

  const [namespaceTokens, setNamespaceTokens] = React.useState<Api.ListNamespaceTokens_Item[] | null>(null);
  const [orgTokens, setOrgTokens] = React.useState<Api.ListNamespaceTokens_Item[] | null>(null);

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    refreshNamespaceTokens(namespaceId, controller.signal);
    refreshOrgTokens(orgId, controller.signal);

    return (): void => {
      controller.abort("Cleaning up effect refreshTokens");
    };
  }, [namespaceId, orgId]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-xl px-8 py-12">
        <div className="flex flex-col space-y-16">
          <div className="flex flex-col space-y-8">
            <div className="flex items-center justify-between space-x-2">
              <div className="text-xs text-zinc-500 max-w-80">
                Subtrace worker tokens are used to authenticate when retrieving tracing data.{" "}
                <a className="brightness-[1.3] hover:brightness-[1.5]" href="https://docs.subtrace.dev">
                  Learn more.
                </a>
              </div>
              <button className="rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1" onClick={() => generateOrgToken(orgId)}>
                <span>Create worker token</span>
                <span>&rarr;</span>
              </button>
            </div>
            {renderOrgTokensList()}
          </div>
          <div className="flex flex-col space-y-8">
            <div className="flex items-center justify-between space-x-2">
              <div className="text-xs text-zinc-500 max-w-80">
                Subtrace tracer tokens are used to authenticate when pushing tracing data.{" "}
                <a className="brightness-[1.3] hover:brightness-[1.5]" href="https://docs.subtrace.dev">
                  Learn more.
                </a>
              </div>
              <button
                className="rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1"
                onClick={() => generateNamespaceToken(namespaceId)}
              >
                <span>Create tracer token</span>
                <span>&rarr;</span>
              </button>
            </div>
            {renderNamespaceTokensList()}
          </div>
        </div>
      </div>
    </div>
  );

  async function deleteNamespaceToken(tokenId: string, tokenName: string, namespaceId: string): Promise<void> {
    const wasCommitted: boolean = await dialogManager.show(
      <DeleteConfirmationDialog
        messageComponent={
          <span>
            <span>Are you sure you want to delete token </span>
            <span className="font-mono">{tokenName}</span>
            <span>? This action cannot be undone.</span>
          </span>
        }
        closeModal={(wasCommitted: boolean) => dialogManager.hide(wasCommitted)}
      />,
    );

    if (!wasCommitted) {
      return;
    }

    const response: Response = await ApiUtils.post("/api/DeleteNamespaceToken", { tokenId });
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to delete a token in this namespace." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }

    await ApiUtils.assertStatus(response, 200);
    await refreshNamespaceTokens(namespaceId);
  }

  async function deleteOrgToken(tokenId: string, tokenName: string, orgId: string): Promise<void> {
    const wasCommitted: boolean = await dialogManager.show(
      <DeleteConfirmationDialog
        messageComponent={
          <span>
            <span>Are you sure you want to delete token </span>
            <span className="font-mono">{tokenName}</span>
            <span>? This action cannot be undone.</span>
          </span>
        }
        closeModal={(wasCommitted: boolean) => dialogManager.hide(wasCommitted)}
      />,
    );

    if (!wasCommitted) {
      return;
    }

    const response: Response = await ApiUtils.post("/api/DeleteOrgToken", { tokenId });
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to delete a token in this org." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }

    await ApiUtils.assertStatus(response, 200);
    await refreshOrgTokens(orgId);
  }

  async function generateNamespaceToken(namespaceId: string): Promise<void> {
    const request: Api.GenerateNamespaceToken_Request = { namespaceId };
    const response: Response = await ApiUtils.post("/api/GenerateNamespaceToken", request);
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to generate a token in this namespace." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }
    await ApiUtils.assertStatus(response, 200);

    const { token }: Api.GenerateNamespaceToken_Response = await response.json();
    await dialogManager.show(<GetApiTokenDialog apiToken={token} closeModal={() => dialogManager.hide(undefined)} />);
    await refreshNamespaceTokens(namespaceId);
  }

  async function generateOrgToken(orgId: string): Promise<void> {
    const request: Api.GenerateOrgToken_Request = { orgId };
    const response: Response = await ApiUtils.post("/api/GenerateOrgToken", request);
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to generate a token in this org." closeModal={() => dialogManager.hide(undefined)} />,
      );
    }
    await ApiUtils.assertStatus(response, 200);

    const { token }: Api.GenerateOrgToken_Response = await response.json();
    await dialogManager.show(<GetApiTokenDialog apiToken={token} closeModal={() => dialogManager.hide(undefined)} />);
    await refreshOrgTokens(orgId);
  }

  async function refreshNamespaceTokens(namespaceId: string, signal?: AbortSignal): Promise<void> {
    const request: Api.ListNamespaceTokens_Request = { namespaceId };
    try {
      const response: Response = await ApiUtils.post("/api/ListNamespaceTokens", request, { signal });
      await ApiUtils.assertStatus(response, 200);

      const { tokens }: Api.ListNamespaceTokens_Response = await response.json();
      setNamespaceTokens(tokens);
    } catch {
      if (signal?.aborted) {
        // Do nothing, the API call was canceled
      }
    }
  }

  async function refreshOrgTokens(orgId: string, signal?: AbortSignal): Promise<void> {
    const request: Api.ListOrgTokens_Request = { orgId };
    try {
      const response: Response = await ApiUtils.post("/api/ListOrgTokens", request, { signal });
      await ApiUtils.assertStatus(response, 200);

      const { tokens }: Api.ListOrgTokens_Response = await response.json();
      setOrgTokens(tokens);
    } catch {
      if (signal?.aborted) {
        // Do nothing, the API call was canceled
      }
    }
  }

  function renderOrgTokensList(): React.ReactNode {
    if (orgTokens === null) {
      return (
        <div className="pt-4 w-full flex items-center">
          <div className="w-full h-full flex justify-center items-center rounded-md text-zinc-600">
            <div className="w-4 h-4 flex justify-center items-center">
              <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
                <Loader2 />
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (orgTokens.length === 0) {
      return (
        <div className="pt-4 w-full flex items-center">
          <div className="w-full h-full flex justify-center items-center border border-zinc-800/80 rounded-md text-zinc-600 py-8">
            <div className="flex justify-center items-center">
              <div className="text-xs flex flex-col space-y-2">
                <span>No worker tokens found in this org.</span>
                <button
                  className="mx-auto rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1"
                  onClick={() => generateOrgToken(orgId)}
                >
                  <span>New token</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="rounded overflow-hidden border border-zinc-800/80 w-full flex flex-col divide-y divide-zinc-800/80">
        {orgTokens.map(({ tokenId, createTime, name, creatorEmail }) => {
          const diff = (Date.now() - new Date(createTime).getTime()) / 1000;

          let count: number, unit: string;
          if (diff < 60) [count, unit] = [Math.round(diff), "second"];
          else if (diff < 60 * 60) [count, unit] = [Math.round(diff / 60), "minute"];
          else if (diff < 24 * 60 * 60) [count, unit] = [Math.round(diff / 60 / 60), "hour"];
          else [count, unit] = [Math.round(diff / 60 / 60 / 24), "day"];
          if (count !== 1) unit += "s";

          return (
            <div className="w-full px-4 py-2 flex justify-between items-center hover:bg-zinc-900 group relative" key={tokenId}>
              <div className="font-mono text-zinc-300 text-sm truncated text-ellipsis">{name}</div>
              <div className="flex flex-col space-y-[1px] items-end group-hover:opacity-0">
                <div className="font-medium text-zinc-600 text-[10px]">{creatorEmail}</div>
                <div title={createTime} className="text-zinc-600/80 text-[10px]">
                  {count} {unit} ago
                </div>
              </div>
              <button
                onClick={() => deleteOrgToken(tokenId, name, orgId)}
                title="Delete"
                className="hidden absolute top-0 right-0 w-16 cursor-pointer group-hover:flex justify-center items-center h-full bg-gradient-to-l from-red-900/20 hover:from-red-900/30 to-transparent text-red-600 hover:text-red-400"
              >
                <span className="w-[14px] h-[14px] flex items-center">
                  <Trash />
                </span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  function renderNamespaceTokensList(): React.ReactNode {
    if (namespaceTokens === null) {
      return (
        <div className="pt-4 w-full flex items-center">
          <div className="w-full h-full flex justify-center items-center rounded-md text-zinc-600">
            <div className="w-4 h-4 flex justify-center items-center">
              <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
                <Loader2 />
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (namespaceTokens.length === 0) {
      return (
        <div className="pt-4 w-full flex items-center">
          <div className="w-full h-full flex justify-center items-center border border-zinc-800/80 rounded-md text-zinc-600 py-8">
            <div className="flex justify-center items-center">
              <div className="text-xs flex flex-col space-y-2">
                <span>No tracer tokens found in this namespace.</span>
                <button
                  className="mx-auto rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1"
                  onClick={() => generateNamespaceToken(namespaceId)}
                >
                  <span>New token</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="rounded overflow-hidden border border-zinc-800/80 w-full flex flex-col divide-y divide-zinc-800/80">
        {namespaceTokens.map(({ tokenId, createTime, name, creatorEmail }) => {
          const diff = (Date.now() - new Date(createTime).getTime()) / 1000;

          let count: number, unit: string;
          if (diff < 60) [count, unit] = [Math.round(diff), "second"];
          else if (diff < 60 * 60) [count, unit] = [Math.round(diff / 60), "minute"];
          else if (diff < 24 * 60 * 60) [count, unit] = [Math.round(diff / 60 / 60), "hour"];
          else [count, unit] = [Math.round(diff / 60 / 60 / 24), "day"];
          if (count !== 1) unit += "s";

          return (
            <div className="w-full px-4 py-2 flex justify-between items-center hover:bg-zinc-900 group relative" key={tokenId}>
              <div className="font-mono text-zinc-300 text-sm truncated text-ellipsis">{name}</div>
              <div className="flex flex-col space-y-[1px] items-end group-hover:opacity-0">
                <div className="font-medium text-zinc-600 text-[10px]">{creatorEmail}</div>
                <div title={createTime} className="text-zinc-600/80 text-[10px]">
                  {count} {unit} ago
                </div>
              </div>
              <button
                onClick={() => deleteNamespaceToken(tokenId, name, namespaceId)}
                title="Delete"
                className="hidden absolute top-0 right-0 w-16 cursor-pointer group-hover:flex justify-center items-center h-full bg-gradient-to-l from-red-900/20 hover:from-red-900/30 to-transparent text-red-600 hover:text-red-400"
              >
                <span className="w-[14px] h-[14px] flex items-center">
                  <Trash />
                </span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}
