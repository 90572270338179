import * as ApiUtils from "ApiUtils";
import { SubtraceEventKind } from "SubtraceEventKind";

// This is intentionally non-blocking so that we don't waste time waiting for the API response.
export function logEvent(eventKind: SubtraceEventKind, eventTags: Record<string, string | number | boolean>): void {
  try {
    ApiUtils.post(
      "/api/Event",
      /* body */ {},
      /* options */ { subtraceTags: { subtrace_event_kind: eventKind, ...eventTags } },
    );
  } catch (error: unknown) {
    // If this fails, it isn't catastrophic. Just log and swallow the exception.
    console.log(error);
  }
}
