import * as Verify from "Utils/Verify";
import { TextFilter, TextFilterKind } from "TextFilter";

export function toSql(name: string, filter: TextFilter): string {
  switch (filter.kind) {
    case TextFilterKind.Equals:
      return `${name} = ${filter.value}`;

    case TextFilterKind.DoesNotEqual:
      return `${name} != ${filter.value}`;

    case TextFilterKind.Like:
      return `${name} LIKE ${filter.value}`;

    case TextFilterKind.NotLike:
      return `${name} NOT LIKE ${filter.value}`;

    default:
      Verify.isNever(filter.kind);
  }
}
