import * as React from "react";
import { CheckIcon, InfoIcon, CircleIcon, PyramidIcon, ShapesIcon, GithubIcon, StarIcon } from "lucide-react";

import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StyleUtils from "Utils/StyleUtils";
import * as Verify from "Utils/Verify";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import DashboardScreenshot from "../images/DashboardScreenshot.png";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NickProfilePicture from "../images/NickProfilePicture.jpg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import YCLogo from "../images/yc_logo.svg";
import XLogo from "../images/x_logo.svg";
import { ExternalLink } from "DesignComponents/ExternalLink";
import { InternalLink } from "DesignComponents/InternalLink";
import { User } from "User";
import { Toggle } from "Toggle";

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Logo(props: { name: string; src: string }): React.ReactElement {
    return (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img
            className="absolute w-full transition ease-in-out contrast-[0.50] saturation-[2.00] group-hover:saturation-[3.00] grayscale brightness-[0.75] group-hover:brightness-[1.25]"
            src={props.src}
            alt={props.name}
          />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]"
          style={{
            background: "radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Logo name="Node.js" src={NodejsLogo} />
          <Logo name="Go" src={GoLogo} />
          <Logo name="Ruby" src={RubyLogo} />
          <Logo name="Java" src={JavaLogo} />
          <Logo name="PHP" src={PhpLogo} />
          <Logo name="Rust" src={RustLogo} />
          <Logo name="Python" src={PythonLogo} />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Logo name="Docker" src={DockerLogo} />
          <Logo name="Kubernetes" src={KubernetesLogo} />
          <Logo name="AWS" src={AWSLogo} />
          <Logo name="Azure" src={AzureLogo} />
          <Logo name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [arePricesExpressedPerYear, setArePricesExpressedPerYear] = React.useState<boolean>(false);
  const [numberOfGithubStars, setNumberOfGithubStars] = React.useState<number | undefined>(undefined);

  React.useEffect(function getNumberOfGithubStars() {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function getAndSetNumberOfGithubStars(): Promise<void> {
      try {
        const response: Response = await fetch("https://api.github.com/repos/subtrace/subtrace", { signal });
        const foo = await response.json();

        if (!signal.aborted) {
          setNumberOfGithubStars(foo.stargazers_count);
        }
      } catch {
        if (signal.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    }

    getAndSetNumberOfGithubStars();

    return (): void => {
      controller.abort("Cleaning up hook getNumberOfGithubStars");
    };
  }, []);

  return (
    <div className="w-full h-vh flex flex-col">
      <div className="flex justify-center m-8">
        <div className="flex items-align justify-between w-full max-w-6xl">
          <div className="my-auto flex items-center space-x-4">
            <InternalLink className="px-4 py-2 mr-4 text-md tracking-widest font-mono text-white" label="subtrace" target="/" />
            <ExternalLink className="px-4 py-2 text-sm" label="Pricing" target="#pricing" />
            <ExternalLink className="px-4 py-2 text-sm" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="px-4 py-2 text-sm" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
          </div>
          {renderTopRightButtons()}
        </div>
      </div>

      <div className="mt-8 flex justify-center">
        <h2 className="font-semibold whitespace-pre text-6xl w-full text-center lg:max-w-xl flex flex-col items-center px-4 text-zinc-100 leading-[1.25]">
          <span>Chrome DevTools</span>
          <span>for your backend</span>
        </h2>
      </div>

      <div className="mt-8 flex justify-center">
        <div className="text-zinc-300 max-w-sm text-center">Subtrace tracks the API requests coming in and going out of your backend infrastructure.</div>
      </div>

      <div className="mt-8 flex justify-center">
        <InternalLink className="px-4 py-2" label="Try for free" target="/login" showArrow showAsButton />
      </div>

      <div className="mt-8 flex justify-center">
        <div className="relative group flex justify-center w-[16rem]">
          <img src={YCLogo} className="rounded-[3px] h-10 w-10 grayscale group-hover:grayscale-0 opacity-[0.50] group-hover:opacity-[1.00]" alt="Backed by Y Combinator" />
          <span className="hidden group-hover:block text-xs text-zinc-400 font-medium absolute bottom-[-1.5rem] left-0 w-full text-center">Backed by Y Combinator</span>
        </div>
      </div>

      <div className="mt-24 flex justify-center md:px-4">
        <img src={DashboardScreenshot} className="w-full max-w-4xl" />
      </div>

      <div className="mt-48 flex justify-center px-4">
        <div className="w-full max-w-5xl flex flex-col">
          <div className="text-zinc-300 font-medium text-xl text-center">Simple. Lightweight. Universal. Pick three.</div>
          <div className="mt-6">{renderDiffView()}</div>
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <a
          className="w-full hover:bg-zinc-900/25 border border-zinc-900 hover:border-zinc-800 px-6 py-6 rounded-md max-w-lg flex flex-col space-y-6"
          href="https://x.com/skeptrune/status/1856499502003433962"
          target="_blank"
          rel="noreferrer"
        >
          <div className="text-zinc-300 text-sm flex flex-col space-y-2">
            <p>
              Got a demo of subtrace from from
              <span> </span>
              <a target="_blank" className="hover:brightness-[1.20]" href="https://x.com/adtac_" rel="noreferrer">
                @adtac_
              </a>
              <span> </span>
              today.
            </p>
            <p>IT'S EVERYTHING I EVER WANTED FOR BACKEND ANALYTICS! 😤</p>
            <p>Proxy-level plugin which puts all your requests into Clickhouse. Can visualize in Grafana or use the Clickhouse db as a backend for chrome devtools.</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex justify-center item-center space-x-2 text-xs">
              <img className="mx-[2px] w-4 h-4 rounded-full" src={NickProfilePicture} />
              <a href="https://x.com/skeptrune" target="_blank" className="text-zinc-300 hover:brightness-[1.2] font-semibold" rel="noreferrer">
                @skeptrune
              </a>
              <a href="https://trieve.ai" target="_blank" className="text-zinc-500 hover:brightness-[1.2] font-medium" rel="noreferrer">
                Founder, Trieve (YC W24)
              </a>
            </div>
            <img className="h-3 w-3 invert opacity-[0.50]" src={XLogo} />
          </div>
        </a>
      </div>

      <div id="pricing" className="mt-48 flex items-center justify-center">
        {renderPricingInformation()}
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-8">
          <div className="flex space-x-8 items-center text-xl font-medium">
            <div className="relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Works the same everywhere.</span>
              <span> </span>
              <span className="text-zinc-400">Every language. Every cloud.</span>
              <Integrations />
            </div>
          </div>

          <div className="flex space-x-8 items-center text-lg font-medium">
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Bring your own storage.</span>
              <span> </span>
              <span className="text-zinc-400">Regain control over your company&apos;s data security. Your tracing data stays in your own servers.</span>
            </div>
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Zero config.</span>
              <span> </span>
              <span className="text-zinc-400">Subtrace fits into your existing backend out of the box. No code changes required.</span>
            </div>
          </div>

          <div className="flex space-x-8 items-center text-sm">
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Made for startups.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Spend more time on what&apos;s important for your product.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Move fast, search things.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Built on top of ClickHouse, the fastest database for analytics.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Fixed pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Get a predictable bill every month. No surprises.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Open source.</span>
              <span> </span>
              <ExternalLink className="font-normal text-zinc-300/95" label="Star us on GitHub." target="https://github.com/subtrace/subtrace" />
            </div>
          </div>

          <div className="w-full h-24 flex px-6 py-3 bg-zinc-900 rounded-xl">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="text-xl font-medium flex items-center text-zinc-200">
                <span className="text-zinc-200">Interested?</span>&nbsp;
                <span className="text-zinc-500">Try for free.</span>
              </div>
              <InternalLink className="px-4 py-2" label="Get started" target="/login" showAsButton showArrow />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 flex justify-center">
        <div className="w-full max-w-3xl py-2 mt-16 mb-32">
          <div className="flex items-center space-x-8">
            <span className="text-[13px] pointer-events-none tracking-wide rounded font-semibold text-zinc-600 font-mono mr-4">subtrace</span>
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="GitHub" openInNewTab target="https://github.com/subtrace/subtrace" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Support" target="mailto:support@subtrace.dev" />
          </div>
        </div>
      </div>
    </div>
  );

  function renderDiffSummary(): React.ReactNode {
    return (
      <div className="flex flex-row items-center space-x-1">
        <span className="text-[#3fb950] font-semibold">+2</span>
        <span className="text-[#f85149] font-semibold">-1</span>
        <div className="flex flex-row space-x-px">
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[repeating-linear-gradient(to_right_bottom,_#fff9,_#fff9_.11rem,_#da3633_.11rem,_#da3633_.22rem)] bg-[#da3633] border rounded-sm border-[#da3633]" />
          <div className="h-2 w-2 bg-[#656c7633] border rounded-sm border-[#3d444d]" />
        </div>
      </div>
    );
  }

  function renderDiffView(): React.ReactNode {
    return (
      <div className="outline outline-[1px] outline-[#3d444d] rounded-md flex flex-col relative">
        <div className="w-full h-10 bg-[#151b23] border-[#3d444d] border-b flex flex-row justify-between text-white text-xs font-mono items-center rounded-t-md px-3">
          <span>Dockerfile</span>
          {renderDiffSummary()}
        </div>
        <div className="w-full h-full grid grid-cols-2 font-mono bg-[#0d1117]">
          <div className="border-[#3d444d] border-r h-full flex flex-col">
            {renderDiffViewLeftSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
          <div className="h-full flex flex-col">
            {renderDiffViewRightSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
        </div>
      </div>
    );
  }

  function renderDiffViewLeftSide(): React.ReactNode {
    return (
      <table className="whitespace-pre overflow-hidden text-[11px] w-full">
        <tbody>
          {renderDiffRow(
            1,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            2,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">WORKDIR</span>
              <span> /app</span>
            </span>,
          )}
          {renderDiffRow(
            3,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> .</span>
              <span> .</span>
            </span>,
          )}
          {renderDiffRow(
            4,
            DiffLineKind.Removed,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;node&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;/app/server.js&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffViewRightSide(): React.ReactNode {
    return (
      <table className="whitespace-pre overflow-hidden text-[11px] w-full table-fixed">
        <tbody>
          {renderDiffRow(
            1,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            2,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">WORKDIR</span>
              <span> /app</span>
            </span>,
          )}
          {renderDiffRow(
            3,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> .</span>
              <span> .</span>
            </span>,
          )}
          {renderDiffRow(
            4,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> curl -fsSLO https://subtrace.dev/download/latest/linux/amd64/subtrace &amp;&amp; chmod +x ./subtrace</span>
            </span>,
          )}
          {renderDiffRow(
            5,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;./subtrace&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;run&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;--&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;node&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;/app/server.js&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffRow(lineNumber: number, diffLineKind: DiffLineKind, text: React.ReactNode): React.ReactNode {
    let diffColumnText: string;
    let lineNumberBackgroundColor: string, diffBackgroundColor: string, lineNumberTextColor: string;

    if (diffLineKind === DiffLineKind.Added) {
      diffColumnText = "+";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#3fb9504d]";
      diffBackgroundColor = "bg-[#2ea04326]";
    } else if (diffLineKind === DiffLineKind.Removed) {
      diffColumnText = "-";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#f851494d]";
      diffBackgroundColor = "bg-[#f8514926]";
    } else if (diffLineKind === DiffLineKind.Unchanged) {
      diffColumnText = "";
      lineNumberTextColor = "text-[#9198a1]";
      lineNumberBackgroundColor = "";
      diffBackgroundColor = "";
    } else {
      Verify.isNever(diffLineKind);
    }

    return (
      <tr className="h-6">
        <td className={`${lineNumberTextColor} text-center w-10 p-0 ${lineNumberBackgroundColor} select-none`}>{lineNumber}</td>
        <td className={`text-white text-center w-6 p-0 ${diffBackgroundColor} select-none`}>{diffColumnText}</td>
        <td className={`text-white ${diffBackgroundColor}`}>{text}</td>
      </tr>
    );
  }

  function renderPricingInformation(): React.ReactNode {
    return (
      <div className="flex flex-col items-center space-y-5">
        <div className="flex flex-row items-center justify-center text-zinc-100 text-s space-x-5">
          <span>Monthly</span>
          <Toggle checked={arePricesExpressedPerYear} onChange={setArePricesExpressedPerYear} />
          <span>Yearly</span>
        </div>
        <div className="h-[600px] max-w-[1200px] grid grid-cols-3 gap-10 text-zinc-300">
          <div className="h-full bg-zinc-900/70 hover:bg-zinc-900 flex flex-col rounded-xl p-8 space-y-4 border-zinc-800 border-t-4">
            <div className="flex justify-center">
              <CircleIcon strokeWidth={1.25} className="text-zinc-600" size={32} />
            </div>
            <span className="self-center text-zinc-200 text-2xl font-medium">Personal</span>
            <div className="flex flex-col flex-1 justify-between">
              <div className="flex flex-col space-y-2 text-sm">
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>10 million requests</span>
                  <div className="relative group">
                    <InfoIcon className="text-zinc-500" size={14} />
                    <span className="hidden group-hover:block absolute bottom-[-2rem] right-0 whitespace-pre px-2 py-1 bg-zinc-800 rounded text-xs border border-1 border-zinc-700">
                      resets monthly
                    </span>
                  </div>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>7 days retention</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>3 team members</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Single sign-on</span>
                </span>
              </div>
              <div className="flex flex-col space-y-6">
                <div className="flex flex-col text-center mr-3 space-y-1">
                  <span className="text-3xl">$0</span>
                  <span className="text-xs">per month</span>
                  <span className="text-xs whitespace-pre text-zinc-400"> </span>
                </div>
                <InternalLink className="text-center py-3 px-6 text-nowrap" label="Try for free" showAsButton showArrow target="/login" />
              </div>
            </div>
          </div>

          <div className="h-full bg-zinc-900/70 hover:bg-zinc-900 flex flex-col rounded-xl p-8 space-y-4 border-zinc-800 border-t-4">
            <div className="flex justify-center">
              <PyramidIcon strokeWidth={1.25} className="text-zinc-600" size={32} />
            </div>
            <span className="self-center text-zinc-200 text-2xl font-medium">Business</span>
            <div className="flex flex-col flex-1 justify-between">
              <div className="flex flex-col space-y-2 text-sm">
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>100 million requests</span>
                  <div className="relative group">
                    <InfoIcon className="text-zinc-500" size={14} />
                    <span className="hidden group-hover:block absolute bottom-[-2rem] right-0 whitespace-pre px-2 py-1 bg-zinc-800 rounded text-xs border border-1 border-zinc-700">
                      resets monthly
                    </span>
                  </div>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>30 days retention</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>10 team members</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Single sign-on</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Onboarding support</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>On-prem installation</span>
                </span>
              </div>
              <div className="flex flex-col space-y-6">
                <div className="flex flex-col text-center mr-3 space-y-1">
                  <span className="text-3xl">${(arePricesExpressedPerYear ? 400 : 500).toLocaleString()}</span>
                  <span className="text-xs">per month</span>
                  <span className="text-xs whitespace-pre text-zinc-400">{arePricesExpressedPerYear ? "(billed yearly)" : " "}</span>
                </div>
                <InternalLink className="text-center py-3 px-6 text-nowrap" label="Get started" showAsButton showArrow target="/login" />
              </div>
            </div>
          </div>

          <div className="h-full bg-zinc-900/70 hover:bg-zinc-900 flex flex-col rounded-xl p-8 space-y-4 border-zinc-800 border-t-4">
            <div className="flex justify-center">
              <ShapesIcon strokeWidth={1.25} className="text-zinc-600" size={32} />
            </div>
            <span className="self-center text-zinc-200 text-2xl font-medium">Enterprise</span>
            <div className="flex flex-col flex-1 justify-between">
              <div className="flex flex-col space-y-2 text-sm">
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>1 billion requests</span>
                  <div className="relative group">
                    <InfoIcon className="text-zinc-500" size={14} />
                    <span className="hidden group-hover:block absolute bottom-[-2rem] right-0 whitespace-pre px-2 py-1 bg-zinc-800 rounded text-xs border border-1 border-zinc-700">
                      resets monthly
                    </span>
                  </div>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>90 days retention</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>50 team members</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Single sign-on</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Onboarding support</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>On-prem installation</span>
                </span>
                <span />
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Audit logs</span>
                </span>
                <span className="flex flex-row space-x-2 items-center">
                  <CheckIcon className="self-start mt-1" size={14} />
                  <span>Custom contracts</span>
                </span>
              </div>
              <div className="flex flex-col space-y-6">
                <div className="flex flex-col text-center mr-3 space-y-1">
                  <span className="text-3xl">${(arePricesExpressedPerYear ? 2000 : 2500).toLocaleString()}</span>
                  <span className="text-xs">per month</span>
                  <span className="text-xs whitespace-pre text-zinc-400">{arePricesExpressedPerYear ? "(billed yearly)" : " "}</span>
                </div>
                <ExternalLink className="text-center py-3 px-6 text-nowrap" label="Talk to the founders" showAsButton showArrow target="mailto:founders@subtrace.dev" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderTopRightButtons(): React.ReactElement | null {
    return (
      <div className="float-right my-auto flex items-center space-x-4">
        {currentUser?.isLoggedIn ? (
          <React.Fragment>
            {renderGithubStars()}
            <ExternalLink className="px-4 py-2" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" />
            <InternalLink className="px-4 py-2" label="Dashboard" showArrow showAsButton target="/dashboard" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {renderGithubStars()}
            <ExternalLink className="px-4 py-2" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" />
            <InternalLink className="px-4 py-2" label="Try for free" target="/login" showArrow showAsButton />
          </React.Fragment>
        )}
      </div>
    );
  }

  function renderGithubStars(): React.ReactElement {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href="https://github.com/subtrace/subtrace"
        className="flex justify-center items-center space-x-2 rounded hover:bg-zinc-900/50 px-3 py-[6px] border border-zinc-900 hover:border-zinc-800 text-zinc-300"
      >
        <GithubIcon className="mr-1" size={14} />
        <span className="text-xs font-medium">Star us on GitHub!</span>
        {numberOfGithubStars != null ? <span className="px-2 py-[1px] text-[11px] rounded-full bg-zinc-700 font-medium">{numberOfGithubStars}</span> : <StarIcon size={15} />}
      </a>
    );
  }
}

const enum DiffLineKind {
  Added = "Added",
  Removed = "Removed",
  Unchanged = "Unchanged",
}
