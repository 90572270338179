import * as React from "react";
import { User, Loader2 } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";

function Row(props: { user: Api.ListUsers_Item }): React.ReactNode {
  const joinTime = new Date(props.user.joinTime);

  return (
    <div className="w-full py-2 items-center grid grid-cols-12 hover:bg-zinc-900/25 group">
      <div className="px-2 col-span-8 text-zinc-300 text-xs truncated text-ellipsis flex items-center space-x-2">
        <span className="w-[14px] h-[14px] flex items-center">
          <User />
        </span>
        <span>{props.user.email}</span>
      </div>
      <div title={joinTime.toISOString()} className="px-2 col-span-3 text-zinc-400 text-xs">
        {joinTime.toDateString()}
      </div>
    </div>
  );
}

function List(props: { users: Api.ListUsers_Item[] }): React.ReactNode {
  return (
    <div className="w-full z-0">
      <div className="flex mb-4">
        <span className="px-2 py-1 bg-zinc-800/80 rounded-full text-zinc-500 text-[10px] font-semibold">
          {props.users.length} {props.users.length === 1 ? "user" : "users"}
        </span>
      </div>
      <div className="w-full flex flex-col divide-y divide-zinc-900">
        <div className="rounded w-full py-2 items-center grid grid-cols-12">
          <div className="px-2 col-span-8 text-zinc-500/80 text-[10px] uppercase font-semibold">Email</div>
          <div className="px-2 col-span-3 text-zinc-500/80 text-[10px] uppercase font-semibold">Joined</div>
        </div>
        {props.users.map((user) => (
          <Row key={user.userId} user={user} />
        ))}
      </div>
    </div>
  );
}

export function UsersPage(): React.ReactNode {
  const [users, setUsers] = React.useState<Api.ListUsers_Item[] | undefined>(undefined);
  const [isAdding, setAdding] = React.useState(false);
  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { namespaceId } = currentNamespace;
  const emailInputId = React.useId();

  const refreshUsers: (signal?: AbortSignal) => Promise<void> = React.useCallback(
    async (signal) => {
      const request: Api.ListUsers_Request = { namespaceId };
      try {
        const response: Response = await ApiUtils.post("/api/ListUsers", request, { signal, subtraceTags: { namespace_id: namespaceId } });
        await ApiUtils.assertStatus(response, 200);
        const { users }: Api.ListUsers_Response = await response.json();
        setUsers(users);
      } catch {
        if (signal?.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    },
    [namespaceId],
  );

  async function addUser(): Promise<void> {
    setAdding(true);

    const email = (document.getElementById(emailInputId) as HTMLInputElement).value;

    const { signal }: AbortController = new AbortController();
    const request: Api.AddUser_Request = { namespaceId, email };
    const response: Response = await ApiUtils.post("/api/AddUser", request, { signal, subtraceTags: { namespace_id: namespaceId } });
    await ApiUtils.assertStatus(response, 200);

    await refreshUsers(signal);

    setAdding(false);
    (document.getElementById(emailInputId) as HTMLInputElement).value = "";
  }

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    if (users === undefined) {
      refreshUsers(controller.signal);
    }
    return (): void => {
      controller.abort("cleaning up effect refreshusers");
    };
  }, [namespaceId, users, refreshUsers]);

  if (users == null) {
    return (
      <div className="pt-4 w-full flex items-center">
        <div className="w-full h-full flex justify-center items-center rounded-md text-zinc-600">
          <div className="w-4 h-4 flex justify-center items-center">
            <span className="w-[14px] h-[14px] flex items-center animate-spin-loader">
              <Loader2 />
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-xl px-8 py-12">
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between space-x-2">
              <div className="text-xs text-zinc-500">
                Invite more coworkers from your organization using their email address to give them access to this namespace.{" "}
                <a className="brightness-[1.3] hover:brightness-[1.5]" href="https://docs.subtrace.dev">
                  Learn more.
                </a>
              </div>
            </div>
            <div className="flex items-center justify-between space-x-2">
              <div className="flex grow text-xs text-zinc-500">
                <input
                  disabled={isAdding}
                  className="bg-zinc-900 text-xs placeholder-zinc-700 text-zinc-400 border-none outline outline-[1px] outline-zinc-800/80 focus:outline-zinc-700/80 w-full h-full px-2 py-[6px] rounded"
                  type="email"
                  id={emailInputId}
                  placeholder="user@example.com"
                />
              </div>
              <button className="rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1" onClick={addUser}>
                <span>Invite</span>
                <span>&rarr;</span>
              </button>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="flex flex-col space-y-8">
              <span className="text-zinc-200 text-sm">Namespace admins</span>
              <List users={users.filter((user) => user.hasWrite)} />
              <span className="text-zinc-200 text-sm">Namespace members</span>
              <List users={users.filter((user) => !user.hasWrite)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
