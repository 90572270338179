const persistentURLParams: string[] = ["devtools"];

export function isEnabledViaURL(flagName: string, defaultValueIfAbsent: boolean = true): boolean {
  const value: string | null = new URLSearchParams(window.location.search).get(flagName);
  if (value === null) {
    return defaultValueIfAbsent;
  }

  if (["0", "n", "no", "f", "false"].includes(value.toLowerCase())) {
    return false;
  }

  if (["1", "y", "yes", "t", "true"].includes(value.toLowerCase())) {
    return true;
  }

  return defaultValueIfAbsent;
}

export function getPersistentURLSearchParams(searchParams: string): string {
  return new URLSearchParams(
    Object.fromEntries(
      [...new URLSearchParams(searchParams).entries()].filter(([key]) =>
        persistentURLParams.includes(key.toLowerCase()),
      ),
    ),
  ).toString();
}
