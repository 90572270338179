import * as ResultUtils from "Utils/ResultUtils";

import { Result } from "Utils/Result";

export function parse(value: string): Result<bigint, string> {
  try {
    return ResultUtils.success(BigInt(value));
  } catch (error: unknown) {
    if (error instanceof Error) {
      return ResultUtils.failure(error.message);
    }

    // We don't know what would cause this, just throw.
    throw error;
  }
}
