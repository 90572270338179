import React from "react";
import { TriangleAlertIcon, XIcon } from "lucide-react";

import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";

export function GetApiTokenDialog(props: GetApiTokenDialogProps): React.ReactElement | null {
  const [showCopiedTooltip, setShowCopiedTooltip] = React.useState<boolean>(false);

  return (
    <div className="relative h-1/4 bg-zinc-900 p-10 text-sm rounded-lg shadow-lg">
      <XIcon
        className={StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white")}
        onClick={() => props.closeModal()}
      />
      <div className="flex flex-col justify-between h-full">
        <div className="text-white">
          <div>
            <span>Your token is: </span>
            <span className="font-mono ">{props.apiToken}</span>
          </div>
          <span className="flex flex-row mt-3 space-x-1">
            <TriangleAlertIcon size={20} className="text-yellow-600" />
            <span> This is the only opportunity to see and record this token.</span>
          </span>
        </div>
        <div className="relative w-fit">
          <Button
            className="rounded h-8 outline-0 border-none"
            label="Copy to clipboard"
            onClick={() => {
              navigator.clipboard.writeText(props.apiToken);
              setShowCopiedTooltip(true);
            }}
            onMouseLeave={() => setShowCopiedTooltip(false)}
          />
          <span
            className={StyleUtils.mergeClassNames(
              "invisible absolute bottom-[calc(100%+5px)] left-1/2 -translate-x-1/2 text-xs border rounded-sm border-zinc-700/80 text-white p-1 bg-zinc-700 text-nowrap",
              { visible: showCopiedTooltip },
            )}
          >
            <span className="absolute top-full left-1/2 -translate-x-1/2">
              <svg className="fill-zinc-700" width="10" height="5" viewBox="0 0 30 10" preserveAspectRatio="none" style={{ display: "block" }}>
                <polygon points="0,0 30,0 15,10" />
              </svg>
            </span>
            Copied
          </span>
        </div>
      </div>
    </div>
  );
}

export interface GetApiTokenDialogProps {
  apiToken: string;

  closeModal: () => void;
}
