export enum SubtraceEventKind {
  ClickhouseError = "ClickhouseError",
  CreateTunnelFailure = "CreateTunnelFailure",
  EmailSubmitError = "EmailSubmitError",
  GetSelfFailure = "GetSelfFailure",
  GridColumnNamesChanged = "GridColumnNamesChanged",
  QueryEvaluated = "QueryEvaluated",
  TunnelError = "TunnelError",
  TunnelResultDecoded = "TunnelResultDecoded",
  TunnelWebSocketClosed = "TunnelWebSocketClosed",
  UncaughtAppBoundaryError = "UncaughtAppBoundaryError",
}
