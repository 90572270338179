// Taken from https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
export const enum WebSocketCloseCode {
  "NormalClosure" = 1000,
  "GoingAway" = 1001,
  "ProtocolError" = 1002,
  "UnsupportedData" = 1003,
  // 1004 is reserved for future use
  "NoStatusRcvd" = 1005,
  "AbnormalClosure" = 1006,
  "InvalidFramePayloadData" = 1007,
  "PolicyViolation" = 1008,
  "MessageTooBig" = 1009,
  "MandatoryExtension" = 1010,
  "InternalError" = 1011,
  "ServiceRestart" = 1012,
  "TryAgainLater" = 1013,
  "BadGateway" = 1014,
  "TLSHandshake" = 1015,
}
