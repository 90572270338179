import { Clause, ClauseKind } from "Clause";
import { GraphQueryColumnName } from "GraphQueryColumnName";
import { GridQuery } from "GridQuery";
import * as DateUtils from "DateUtils";
import * as MathUtils from "Utils/MathUtils";
import * as NumericalFilterUtils from "NumericalFilterUtils";
import * as RequestGraphConstants from "RequestGraphConstants";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as TextFilterUtils from "TextFilterUtils";
import * as TimestampFilterUtils from "TimestampFilterUtils";
import * as Verify from "Utils/Verify";
import { GraphQuery } from "GraphQuery";
import { TimestampFilter } from "TimestampFilter";

export const GRID_QUERY_RESULT_MAX_ROWS: number = 1000;
const timestampColumnName: string = SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time);

export const GRID_QUERY_DEFAULT_COLUMNS: SubtraceEvent.KnownFields[] = [
  SubtraceEvent.KnownFields.time,
  SubtraceEvent.KnownFields.http_duration,
  SubtraceEvent.KnownFields.http_resp_status_code,
  SubtraceEvent.KnownFields.http_req_method,
  SubtraceEvent.KnownFields.http_req_path,
];

export function toGraphSqlQuery(namespaceId: string, query: GraphQuery): string {
  const table = `subtrace_events_${namespaceId.replaceAll("-", "")}`;
  const sqlFiltersString: string = getSqlFiltersString(query.clauses, query.timestampFilter);

  const granularity: number =
    MathUtils.ensureInteger(query.timestampFilter.upperBoundTimestamp - query.timestampFilter.lowerBoundTimestamp) /
    RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN;
  const tzOffset: number = DateUtils.getTimeZoneOffset(query.displayTimeZone);

  // Upgrading to Clickhouse 24.9 would allow us to simplify this query. They introduced another overload to
  // `toStartOfInterval` that allows us to specify a starting point, which avoids the awkward sequence of
  // subtract->round down->add operations needed to simulate the same behavior.
  // For more information see https://github.com/ClickHouse/ClickHouse/issues/55619
  // TODO(sachin): Change this query when we upgrade to CH 24.9
  return `
    SELECT
    COUNT() as ${GraphQueryColumnName.Count},
    addMilliseconds(toStartOfInterval(subtractMilliseconds(${timestampColumnName}, ${tzOffset}), INTERVAL ${granularity} MILLISECONDS), ${tzOffset}) as ${GraphQueryColumnName.BinnedTimestamp}
    FROM ${table}
    ${sqlFiltersString.length > 0 ? `WHERE ${sqlFiltersString}` : ""}
    GROUP BY ${GraphQueryColumnName.BinnedTimestamp};`;
}

export function toGridSqlQuery(namespaceId: string, query: GridQuery): string {
  const table = `subtrace_events_${namespaceId.replaceAll("-", "")}`;
  const sqlFiltersString: string = getSqlFiltersString(query.clauses, query.timestampFilter);

  return `SELECT * FROM ${table}
    ${sqlFiltersString.length > 0 ? `WHERE ${sqlFiltersString}` : ""}
    ORDER BY ${timestampColumnName} DESC
    LIMIT ${GRID_QUERY_RESULT_MAX_ROWS};`;
}

function getSqlFiltersString(clauses: Clause[], timestampFilter: TimestampFilter | undefined): string {
  const sqlFilters: string[] = [];

  for (const clause of clauses) {
    switch (clause.kind) {
      case ClauseKind.Number:
        sqlFilters.push(NumericalFilterUtils.toSql(clause.columnName, clause.filter));
        break;

      case ClauseKind.String:
        sqlFilters.push(TextFilterUtils.toSql(clause.columnName, clause.filter));
        break;

      case ClauseKind.Timestamp:
        sqlFilters.push(TimestampFilterUtils.toSql(clause.columnName, clause.filter));
        break;

      default:
        Verify.isNever(clause);
    }
  }

  if (timestampFilter) {
    sqlFilters.push(
      TimestampFilterUtils.toSql(SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time), timestampFilter),
    );
  }

  return sqlFilters.map((clause) => `(${clause})`).join(" AND ");
}
