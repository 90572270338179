import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as EventLogger from "EventLogger";
import * as JsonUtils from "JsonUtils";
import * as StrictUtils from "Utils/StrictUtils";
import { DialogContext } from "DialogContext";
import { DialogManager } from "DialogManager";
import { NamespaceContext } from "NamespaceContext";
import { NamespaceManager } from "NamespaceManager";
import { OrgContext } from "OrgContext";
import { SubtraceEventKind } from "SubtraceEventKind";
import { User } from "User";

export function useCurrentOrg(): Api.ListOrgs_Item {
  return StrictUtils.ensureDefined(React.useContext(OrgContext));
}

export function useCurrentUser(): User | undefined {
  const [currentUser, setCurrentUser] = React.useState<User | undefined>(undefined);

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function tryGetSelf(): Promise<void> {
      try {
        const response: Response = await ApiUtils.get("/api/GetSelf", { signal });
        if (response.status !== 200) {
          setCurrentUser({ isLoggedIn: false });
          return;
        }

        const { email, userId }: Api.GetSelf_Response = await response.json();
        setCurrentUser({ isLoggedIn: true, email, userId });
      } catch (error: unknown) {
        if (controller.signal.aborted) {
          // Do nothing since the API call was aborted, likely because the user navigated back in their
          // browser or reloaded the page.
        } else {
          setCurrentUser({ isLoggedIn: false });
          EventLogger.logEvent(SubtraceEventKind.GetSelfFailure, {
            error_message: error instanceof Error ? error.message : JsonUtils.stringify(error),
            error_name: error instanceof Error ? error.name : "",
            error_stack: error instanceof Error ? error.stack ?? "" : "",
          });
        }
      }
    }

    tryGetSelf();

    return (): void => {
      controller.abort("Cleaning up effect: useCurrentUser");
    };
  }, []);

  return currentUser;
}

export function useDialogManager(): DialogManager {
  return StrictUtils.ensureDefined(React.useContext(DialogContext));
}

export function useNamespaceManager(): NamespaceManager {
  return StrictUtils.ensureDefined(React.useContext(NamespaceContext));
}
