export type Result<TSuccess, TFailure> = SuccessResult<TSuccess> | FailureResult<TFailure>;

export const enum ResultKind {
  Success = "Success",
  Failure = "Failure",
}

export type SuccessResult<TSuccess> = {
  kind: ResultKind.Success;
  value: TSuccess;
};

export type FailureResult<TFailure> = {
  kind: ResultKind.Failure;
  error: TFailure;
};
