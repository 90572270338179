export function tokenize(input: string): Token[] {
  let index: number = 0;
  const result: Token[] = [];
  while (index < input.length) {
    let hasMatch: boolean = false;

    for (const { kind, regex } of tokenParsers) {
      regex.lastIndex = index;
      const matched: RegExpExecArray | null = regex.exec(input);
      if (matched !== null) {
        result.push({
          kind,
          range: {
            start: index,
            end: index + matched[0].length,
          },
          value: input.substring(index, index + matched[0].length),
        });
        index += matched[0].length;
        hasMatch = true;
        break;
      }
    }
    if (!hasMatch) {
      throw new Error(`Unexpected token at index ${index}`);
    }
  }

  return result;
}

export type Token = {
  kind: TokenKind;
  range: {
    start: number;
    end: number;
  };
  value: string;
};

export const enum TokenKind {
  META_ERROR_CATCHALL = "META_CATCHALL",
  Equal = "Equal",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  Identifier = "Identifier",
  IncompleteSingleQuotedString = "IncompleteSingleQuotedString",
  LesserThan = "LesserThan",
  LesserThanOrEqualTo = "LesserThanOrEqualTo",
  Not = "Not",
  Like = "Like",
  NotEqual = "NotEqual",
  NotLike = "NotLike",
  Number = "Number",
  SingleQuotedString = "SingleQuotedString",
  Whitespace = "Whitespace",
}

type TokenParser = {
  kind: TokenKind;
  regex: RegExp;
  importance: number;
};

const tokenParsers: TokenParser[] = [
  { kind: TokenKind.Equal, importance: 2, regex: /=/y },
  { kind: TokenKind.GreaterThan, importance: 2, regex: />/y },
  { kind: TokenKind.GreaterThanOrEqualTo, importance: 2, regex: />=/y },
  { kind: TokenKind.Identifier, importance: 0, regex: /[a-zA-Z_]\w*/y },
  { kind: TokenKind.LesserThan, importance: 2, regex: /</y },
  { kind: TokenKind.LesserThanOrEqualTo, importance: 2, regex: /<=/y },
  { kind: TokenKind.Like, importance: 2, regex: /LIKE/y },
  { kind: TokenKind.NotEqual, importance: 2, regex: /!=|<>/y },
  { kind: TokenKind.NotLike, importance: 2, regex: /NOT LIKE/y },
  { kind: TokenKind.Not, importance: 1, regex: /NOT/y },
  { kind: TokenKind.Number, importance: 1, regex: /[1-9][0-9]*/y },
  { kind: TokenKind.SingleQuotedString, importance: 1, regex: /'[^']*'/y },
  { kind: TokenKind.IncompleteSingleQuotedString, importance: 1, regex: /'[^']*/y },
  { kind: TokenKind.Whitespace, importance: 0, regex: /\s+/y },
  { kind: TokenKind.META_ERROR_CATCHALL, importance: 0, regex: /[^\s]+/y },
].toSorted((t1, t2) => t2.importance - t1.importance);
