import { AsyncValue, AsyncValueKind, FulfilledValue, PendingValue, UninitializedValue } from "Utils/AsyncValue";

// Constructors
export function fulfilled<T>(value: T): FulfilledValue<T> {
  return { kind: AsyncValueKind.Fulfilled, value };
}

export function pending<T>(promise: Promise<T>): PendingValue<T> {
  return { kind: AsyncValueKind.Pending, promise };
}

export function uninitialized(): UninitializedValue {
  return { kind: AsyncValueKind.Uninitialized };
}

// Type checkers
export function isFulfilled<T>(asyncValue: AsyncValue<T>): asyncValue is FulfilledValue<T> {
  return asyncValue.kind === AsyncValueKind.Fulfilled;
}

export function isPending<T>(asyncValue: AsyncValue<T>): asyncValue is PendingValue<T> {
  return asyncValue.kind === AsyncValueKind.Pending;
}

export function isUninitialized<T>(asyncValue: AsyncValue<T>): asyncValue is UninitializedValue {
  return asyncValue.kind === AsyncValueKind.Uninitialized;
}
