import * as RequestGraphConstants from "RequestGraphConstants";

export function getIdealGranularity(windowDuration: number): number {
  for (const granularity of RequestGraphConstants.knownGranularities) {
    if (RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * granularity >= windowDuration) {
      return granularity;
    }
  }
  return RequestGraphConstants.DEFAULT_GRANULARITY;
}
