export const DEFAULT_GRANULARITY: number = 1 * 1000;
export const EPSILON: number = 1e-3;
export const NUMBER_OF_BARS_ON_SCREEN: number = 120;
export const PAN_HANDLE_HEIGHT_PERCENTAGE: number = 20;
export const TALLEST_BAR_HEIGHT_PERCENTAGE: number = 75;

export const knownGranularities: number[] = [
  1 * 1000,
  5 * 1000,
  30 * 1000,
  60 * 1000,
  5 * 60 * 1000,
  10 * 60 * 1000,
  60 * 60 * 1000,
  3 * 60 * 60 * 1000,
];
