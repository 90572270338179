import React from "react";
import { BanIcon, XIcon } from "lucide-react";

import * as StyleUtils from "Utils/StyleUtils";

export function InsufficientPermissionsDialog(props: InsufficientPermissionsDialogProps): React.ReactElement {
  return (
    <div className="relative h-1/4 min-w-[25%] bg-zinc-900 p-10 text-sm rounded-lg shadow-lg">
      <XIcon
        className={StyleUtils.mergeClassNames("rounded-full absolute top-3 right-3 h-4 w-4 text-white", "hover:text-gray-900 hover:bg-white")}
        onClick={() => props.closeModal()}
      />
      <div className="flex flex-col justify-between h-full">
        <div className="text-white">
          <span className="flex flex-row mt-3 space-x-1">
            <BanIcon size={20} className="text-red-600 mr-2" />
            <span>{props.message}</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export interface InsufficientPermissionsDialogProps {
  message: string;

  closeModal: () => void;
}
