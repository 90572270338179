import * as React from "react";

import * as ApiEndpointUtils from "ApiEndpointUtils";
import { SignInButton } from "SignInButton";
import { Logo } from "Logo";

export function LoginPage(): React.ReactElement {
  return (
    <div className="w-dvw h-dvh flex flex-col">
      <div className="flex grow justify-center items-begin pt-8">
        <div className="w-full max-w-[20rem] h-full max-h-[30rem] flex flex-col space-y-4">
          <div className="w-full h-full flex flex-col align-middle justify-between bg-zinc-900/20 border border-zinc-900/50 rounded-lg px-4 py-4">
            <div className="flex justify-center items-center py-2 grayscale opacity-[0.75]">
              <Logo className="w-[8rem]" />
            </div>
            <div className="flex flex-col space-y-3">
              <SignInButton className="rounded bg-white py-[6px] text-sm" label="Log in with Google" onClick={() => login("/api/GoogleRedirect")} />
              <SignInButton className="rounded bg-white py-[6px] text-sm" label="Log in with Github" onClick={() => login("/api/GithubRedirect")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function login(path: string): void {
    const nextUrl: string | null = new URL(window.location.href).searchParams.get("next");
    const loginUrl: URL = new URL(ApiEndpointUtils.getApiEndpoint() + path);
    if (nextUrl !== null) {
      loginUrl.searchParams.append("next", nextUrl);
    }

    window.location.href = loginUrl.href;
  }
}
