export function clamp(value: number, min: number, max: number): number {
  return Math.max(min, Math.min(value, max));
}

export function ensureInteger(value: number): number {
  if (!Number.isInteger(value)) {
    throw new Error(`Expected an integer, got value: ${value}`);
  }
  return value;
}

export function getFraction(value: number, start: number, end: number): number {
  return (value - start) / (end - start);
}

export function getInterpolatedValue(start: number, end: number, fraction: number): number {
  return start * (1 - fraction) + end * fraction;
}

export function isBetween(value: number, start: number, end: number): boolean {
  return value >= start && value <= end;
}

export function roundDownToGranularity(value: number, granularity: number, zeroPoint: number): number {
  return zeroPoint + Math.floor((value - zeroPoint) / granularity) * granularity;
}

export function roundToGranularity(value: number, granularity: number, zeroPoint: number): number {
  return roundDownToGranularity(value + granularity / 2, granularity, zeroPoint);
}

export function roundUpToGranularity(value: number, granularity: number, zeroPoint: number): number {
  return zeroPoint + Math.ceil((value - zeroPoint) / granularity) * granularity;
}
