export class Deferred<T> {
  public readonly promise: Promise<T>;

  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (error: Error) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public resolve(value: T): void {
    this._resolve(value);
  }

  public reject(error: Error): void {
    this._reject(error);
  }
}
