import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StyleUtils from "Utils/StyleUtils";
import { Button } from "DesignComponents/Button";
import { DeleteConfirmationDialog } from "DeleteConfirmationDialog";
import { DialogManager } from "DialogManager";
import { InsufficientPermissionsDialog } from "InsufficientPermissionsDialog";
import { NamespaceCreationDialog } from "NamespaceCreationDialog";

export function NamespacesPage(): React.ReactNode {
  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const [allNamespaces, setAllNamespaces] = React.useState<Api.ListNamespaces_Item[] | undefined>(undefined);
  const [orgId, setOrgId] = React.useState<string | undefined>(undefined);
  const dialogManager: DialogManager = CustomHooks.useDialogManager();

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    refreshNamespaces(controller.signal);

    return (): void => {
      controller.abort("Cleaning up effect getNamespaces");
    };
  }, []);

  if (orgId == null || allNamespaces == null) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-xl px-8 py-12">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-row justify-end">
              <Button className="h-8 outline-0 font-medium text-xs bg-sky-900 hover:bg-sky-800 text-zinc-200" label="Create new namespace" onClick={() => createNamespace(orgId)} />
            </div>

            <div className="flex flex-col divide-y border border-zinc-800/80">{allNamespaces.map((namespace) => renderRow(namespace))}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  async function refreshNamespaces(signal?: AbortSignal): Promise<void> {
    try {
      const listOrgsRequest: Api.ListOrgs_Request = {};
      const listOrgsResponse: Response = await ApiUtils.post("/api/ListOrgs", listOrgsRequest, { signal });
      await ApiUtils.assertStatus(listOrgsResponse, 200);
      const { orgs }: Api.ListOrgs_Response = await listOrgsResponse.json();
      ArrayUtils.assertLengthAtLeast(orgs, 1);

      // We currently assume that we're always dealing with the first org
      const orgId: string = orgs[0].orgId;
      setOrgId(orgId);
      const request: Api.ListNamespaces_Request = { orgId };
      const response: Response = await ApiUtils.post("/api/ListNamespaces", request, { signal, subtraceTags: { org_id: orgId } });
      await ApiUtils.assertStatus(response, 200);

      const { namespaces }: Api.ListNamespaces_Response = await response.json();
      setAllNamespaces(namespaces);
    } catch {
      if (signal?.aborted) {
        // Do nothing, the API call was canceled
      }
    }
  }

  function renderRow(namespace: Api.ListNamespaces_Item): React.ReactElement {
    return (
      <div
        key={namespace.namespaceId}
        className={StyleUtils.mergeClassNames("flex flex-row space-x-4 h-12 px-4 border-zinc-800/80 justify-between group/item items-center text-white", {
          "bg-zinc-700/40": namespace.namespaceId === currentNamespace.namespaceId,
        })}
      >
        <div className="flex flex-col text-xs overflow-x-hidden">
          <span className="overflow-x-hidden text-nowrap text-ellipsis">{namespace.name}</span>
          <span className="text-zinc-500">Created: {new Date(namespace.createTime).toDateString()}</span>
        </div>
        {namespace.namespaceId === currentNamespace.namespaceId ? (
          <span className="w-16 text-xs flex flex-row justify-center">(Current)</span>
        ) : (
          <Button
            className={StyleUtils.mergeClassNames(
              "text-xs invisible text-red-600 bg-zinc-800 h-8 w-16 outline-0 rounded",
              "hover:bg-red-600 hover:text-white",
              "group-hover/item:visible",
            )}
            label="Delete"
            onClick={() => deleteNamespace(namespace)}
          />
        )}
      </div>
    );
  }

  async function createNamespace(orgId: string): Promise<void> {
    const name: string | undefined = await dialogManager.show(<NamespaceCreationDialog closeModal={(name) => dialogManager.hide(name)} />);

    if (name === undefined) {
      return;
    }

    const request: Api.CreateNamespace_Request = { orgId, name };
    const response: Response = await ApiUtils.post("/api/CreateNamespace", request, /* options */ { subtraceTags: { org_id: orgId } });
    if (response.status === 403) {
      return dialogManager.show(<InsufficientPermissionsDialog message="You do not have permissions to create a namespace." closeModal={() => dialogManager.hide(undefined)} />);
    }

    await ApiUtils.assertStatus(response, 200);
    await refreshNamespaces();
  }

  async function deleteNamespace(namespace: Api.ListNamespaces_Item): Promise<void> {
    const wasCommitted: boolean = await dialogManager.show(
      <DeleteConfirmationDialog
        messageComponent={
          <span>
            <span>Are you sure you want to delete namespace </span>
            <span className="font-bold">{namespace.name}</span>
            <span>? This action cannot be undone.</span>
          </span>
        }
        closeModal={(wasCommitted: boolean) => dialogManager.hide(wasCommitted)}
      />,
    );

    if (!wasCommitted) {
      return;
    }

    const request: Api.DeleteNamespace_Request = { namespaceId: namespace.namespaceId };
    const response: Response = await ApiUtils.post("/api/DeleteNamespace", request, /* options */ { subtraceTags: { namespace_id: namespace.namespaceId } });
    if (response.status === 403) {
      return dialogManager.show(
        <InsufficientPermissionsDialog message="You do not have permissions to delete this namespace." closeModal={() => dialogManager.hide(/* wasCommitted */ false)} />,
      );
    }

    await ApiUtils.assertStatus(response, 200);
    await refreshNamespaces();
  }
}
