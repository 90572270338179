import { FailureResult, Result, ResultKind, SuccessResult } from "./Result";

// Constructors
export function success<TSuccess>(value: TSuccess): SuccessResult<TSuccess> {
  return {
    kind: ResultKind.Success,
    value,
  };
}

export function failure<TFailure>(error: TFailure): FailureResult<TFailure> {
  return {
    kind: ResultKind.Failure,
    error,
  };
}

// Type guards
export function isSuccess<TSuccess, TFailure>(result: Result<TSuccess, TFailure>): result is SuccessResult<TSuccess> {
  return result.kind === ResultKind.Success;
}

export function isFailure<TSuccess, TFailure>(result: Result<TSuccess, TFailure>): result is FailureResult<TFailure> {
  return result.kind === ResultKind.Failure;
}
